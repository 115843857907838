


.credential-accordion-box{
  min-height: 60vh;

  .accordion-covered-credential-body{
    padding: 8px 6px;
    height: 50vh;
    overflow: auto;
  }
  .MuiAccordion-root{
    margin-bottom: 8px;
  }
  .MuiAccordion-root.Mui-expanded{
    margin-bottom: 8px;
  }
  .MuiAccordionSummary-root{
    min-height: 50px;
    padding: 0;
  }
  .MuiAccordion-root:before{
    height: 0;
  }
  .MuiPaper-elevation1{
    box-shadow: 0px 0px 6px 0px rgba(138, 138, 138, 0.24);
  }
  .MuiAccordion-rounded{
    border-radius: 8px!important;
  }
  .MuiAccordionSummary-content{
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded{
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded{
    min-height: 50px;
  }
  .MuiAccordionDetails-root{
    width: 100%;
  }

  .covered-credentials-details-title{
    color: #4B5C68;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .covered-credentials{
    padding: 12px 16px;
    border-radius: 8px;
    background: rgba(235, 242, 253, 0.50);
    margin-bottom: 8px;
    color: #4B5C68;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 49%;
  }
  .covered-credentials-details-body{
    width: 100%;
  }
  .covered-credentials-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}


.employment-modal{
  width: 460px;
}


.staff-avatar{
  border-radius: 40px!important;
  object-fit: cover;
}

.tool-inf-box{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 16px 0;
  color: #172B4D;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}