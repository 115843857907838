
.generate-claim-filter-section {
  display: flex;
  align-items: center;
  gap: 24px;

  //.MuiAutocomplete-root {
  //  //width: 170px !important;
  //  //@media (min-width: 1919px) {
  //  //  width: 220px !important;
  //  //}
  //}

}

.remove-from-claim {
  white-space: nowrap;
  height: 30px;
  padding: 2px 12px;
  color: #9E2608;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.removed-text {
  height: 32px;
  padding: 2px 12px;
  color: #DE350B;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.form-paper-wrapper {
  width: 820px;
  height: 90vh;
  max-height: 1100px;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  & ::-webkit-scrollbar {
    scrollbar-width: none !important;
  }

  * {
    scrollbar-width: none !important;
  }
}


.generate-or-cancel-btn-style {
  max-width: 446px;
  width: 100%;
  @media (min-width: 1919px) {
    max-width: 540px;
  }

  & button {
    width: 215px;
    height: 36px !important;
    font-size: 14px !important;

    @media (min-width: 1919px) {
      width: 262px;
    }

    &:first-of-type {
      background-color: #F0F8FF !important;
    }
  }
}

.generate-information-wrapper {
  @include flexStart;
  font-family: "Open Sans", sans-serif;
  border-radius: 4px;
  background: #F2F4F8;
  width: 334px;
  height: 48px;
  padding: 8px;
  color: #51566D;
  gap: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.resolve-btn-box {
  width: 61px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #0C66E4;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.resolve-btn-big {
  padding: 0 16px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #0C66E4;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.resolved-btn-box {
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #038A4D;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;

  img {
    filter: brightness(0) saturate(100%) invert(33%) sepia(78%) saturate(1102%) hue-rotate(121deg) brightness(91%) contrast(98%);
  }

  p {
    display: none;
    @media (min-width: 1919px) {
      display: block;
    }
  }
}

.detail-resolved-btn-box {
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #038A4D;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;

  img {
    filter: brightness(0) saturate(100%) invert(33%) sepia(78%) saturate(1102%) hue-rotate(121deg) brightness(91%) contrast(98%);
  }
}


.generate-claim-modal {
  .MuiTableCell-sizeSmall {
    padding: 8px 12px!important;
    @media (min-width: 1919px) {
      padding: 8px 16px!important;
    }
  }
}