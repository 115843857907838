@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimPro-Regular.eot');
  src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
  url('../fonts/GTWalsheimPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GTWalsheimPro-Regular.woff2') format('woff2'),
  url('../fonts/GTWalsheimPro-Regular.woff') format('woff'),
  url('../fonts/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimPro-Medium.eot');
  src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
  url('../fonts/GTWalsheimPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GTWalsheimPro-Medium.woff2') format('woff2'),
  url('../fonts/GTWalsheimPro-Medium.woff') format('woff'),
  url('../fonts/GTWalsheimPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimPro-Bold.eot');
  src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
  url('../fonts/GTWalsheimPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GTWalsheimPro-Bold.woff2') format('woff2'),
  url('../fonts/GTWalsheimPro-Bold.woff') format('woff'),
  url('../fonts/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// — Theme Colors
$themePrimary: #0090f0;
$themeBlue: #347AF0;
$themePrimaryBold: #1668E8;
$themePrimaryTiny: #0065FF;
$themeYellow: #ffc051;
$themeGreen: #6fd231;
$themeRed: #fe7070;
$errorColor: #E90715;
$errorColorInput: #FF453A;
$disableColorInput: rgba(52, 122, 240, 0.50);
$errorColorMainInput: #FE7070;
$errorPasswordCheck: #A73205;
$successPasswordCheck: #008547;

// — Background Colors
$backgroundPrimaryGradient: linear-gradient(180deg, #EEF3FE 0%, rgba(242, 242, 242, 0.32) 131.32%);
$backgroundPrimary: #ffffff;
$backgroundSecondary: #f7f9fc;
$backgroundSecondary2: #EBEFF5;
$backgroundSecondary3: #FDFDFD;
$backgroundSecondary4: #D2D3D5;
$backgroundSecondary5: #FFF;
$backgroundSecondary6: #F5F5F9;
$backgroundSecondary7: #F9FAFC;
$backgroundSecondary8: #2A2F35;
$backgroundSecondary9: #EAEFF6;
$backgroundSecondary10: #2D3444;
$backgroundSecondary11: #F8F8FA;
$backgroundSecondary12: #F7F7F8;
$backgroundSecondary13: #EBF2FD;
$backgroundSecondary14: #E4E7EC;
$backgroundSecondary15: #EFF2F6;
$backgroundSecondary16: #F8FAFB;
$backgroundSecondary17: #D0D5DD;
$backgroundOrange: #FFC051;
$backgroundBorder: #EEF2F6;
$backgroundDark: #1a3855;
$backgroundButton: #2A3142;
$inputBorder: #9D9D9D;


// — Text Colors
$textPrimary: #122942;
$textSecondary: #2d4365;
$titleColor: #101214;
$titleColor3: #292A2E;
$titleColor4: #12141D;
$titleColor5: #828AA5;
$titleColor6: #091439;
$titleColor7: #2D4365;
$titleColor8: #202939;
$titleColor9: #2B2B2B;
$titleColor10: #2A374E;
$titleColor11: #4B5C68;
$titleColor12: #0C66E4;
$titleColor13: #052B5F;
$titleColor14: #0948A1;
$textWhite: #ffffff;
$textWhit2: #E3E3E3;
$textWhit3: #FAFAFA;
$textWhit4: #172B4D;
$textWhit5: #475467;
$textGrey: #ffffff82;
$textDark: #344054;

$mobile: 735px;
$tablet: 768px;
$desktop: 1279px;


@mixin resetBtn {
  background: transparent;
  border: none;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@mixin spaceBetweenStart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

@mixin spaceBetweenEnd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

@mixin alignCenter {
  display: flex;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexStart {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@mixin alignCenter {
  display: flex;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexStart {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}


#spin {
  display: none;
}

.MuiTabs-root {
  min-height: 36px !important;
}

.MuiAppBar-colorPrimary {
  background: #ffffff 0 0 no-repeat padding-box !important;
}

.my-profile {
  .MuiBackdrop-root {
    background: transparent !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset;
  color: #545f7e;
}

input {
  &::placeholder {
    color: #4b5c68 !important;
  }
}

.success {
  .Toastify__progress-bar--default {
    background: #00c851;
  }
}

.error {
  .Toastify__progress-bar--default {
    background: #d72525;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rbc-time-view {
  background: #ffffff 0% 0% no-repeat padding-box;
  //box-shadow: 0px 0px 6px #347af04d;
  //border-radius: 8px;
  border: none;
}

.rbc-time-header-content {
  border-color: #ebf2fd;
  //border: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #ebf2fd;
}

.rbc-time-content {
  border-top: 1px solid #ebf2fd;
  //max-height: 60vh!important;
  overflow: auto;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: #ebf2fd 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 0px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #4b5c68;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background: #d2dff5 0% 0% no-repeat padding-box;
}

.rbc-timeslot-group {
  min-height: 40px;
}


.rbc-timeslot-group {
  color: #347af0;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.rbc-header {
  color: #347af0;
  font-size: 14px;
  line-height: 30px;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ebf2fd;
}

.rbc-time-view .rbc-allday-cell {
  height: 17px;
  border-left: 1px solid #ebf2fd;
}

.rbc-today {
  background: transparent;
}

.rbc-allday-cell {
  display: none;
}

.rbc-header + .rbc-header {
  //border: none;
  height: 35px;
  border-color: #ebf2fd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-today {
  color: white;

  a {
    height: 27px;
    border-radius: 4px;
    background: #347AF0;
    color: white;
    padding: 2px 16px;
    display: flex;
    align-items: center;
  }
}

.rbc-row .rbc-time-header-cell {
  border-right: 1px solid #ebf2fd;
}

.rbc-time-gutter {
  width: 84px;
}

.rbc-time-header.rbc-overflowing {
  border-top: 1px solid #ebf2fd;
  border-right: none;
  margin-right: 2px !important;
}

.rbc-overlay {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}


.widget-cards-box{
  overflow-y: hidden ;
    &::-webkit-scrollbar {
      display: none;
    }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #9FB1BD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9FB1BD;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #9FB1BD #f1f1f1;
  }


  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #F2F5F7;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #9FB1BD;
    border-radius: 10px;
  }

}

//body {
//  &::-webkit-scrollbar {
//    display: none;
//  }
//}
//
//::-webkit-scrollbar {
//  width: 12px;
//}
//
//::-webkit-scrollbar-track {
//  background: #9FB1BD;
//  border-radius: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: #9FB1BD;
//  border-radius: 10px;
//  border: 2px solid #f1f1f1;
//}
//
//::-webkit-scrollbar-thumb:hover {
//  background-color: #555;
//}
//
//* {
//  scrollbar-width: thin;
//  scrollbar-color: #9FB1BD #f1f1f1;
//}


//::-webkit-scrollbar {
//  width: 5px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  background-color: #F2F5F7;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background-color: #9FB1BD;
//  border-radius: 10px;
//}

::-webkit-search-cancel-button {
  cursor: pointer;
}

.flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loader-container-style {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

.invoice-table-actions {
  //.MuiTableCell-sizeSmall {
  //  padding: 0px 16px 0px 16px !important;
  //
  //}

  //select {
  //  border: none;
  //  background: transparent;
  //  font-weight: 400;
  //  font-size: 14px;
  //  line-height: 19px;
  //  color: #4B5C68;
  //  height: 50px;
  //  width: 100%;
  //  outline: none;
  //}
  //
  //select {
  //  -webkit-appearance: none;
  //  -moz-appearance: none;
  //  text-indent: 1px;
  //  text-overflow: '';
  //}
  //
  //select:disabled {
  //  color: #4B5C68;
  //}

  input {
    width: 100%;
    height: 50px;
    border: none;
    background: transparent;
    outline: none;
    color: #51566D !important;
  }

  .edit {
    background: transparent;
    border: none;
  }

  .pay {
    width: 95px;
    height: 32px;
    background: #347AF0;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    margin-left: 12px;
    white-space: nowrap;
  }

  .save {
    background: transparent;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #6FD231;
  }
}


.invoice-table-input {
  //padding: 0px 16px;
  height: 100%;
  background: white;
  padding: 0 16px 0 0;

  input {
    height: 49px;
    border: none;
    background: transparent;
    color: #51566D;
    font-size: 14px;
    line-height: 22px;
    outline: none;
    width: 100%;
  }
}

.active-status {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #6FD231;
}

.inactive-status {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #FE7070;
}

.percentage-box-style {
  display: flex;
  align-items: center;

  .MuiCircularProgress-colorPrimary {
    color: #347AF0;
  }
}

.percent-text-style {
  margin-right: 8px;
  min-width: 55px;
  width: auto;
}


.text-style {
  p span {
    width: 100%;
  }

  width: 100%;
}

.signature-file-wrapper {
  img {
    width: 80px;
    height: 40px;
    object-fit: cover;
    position: absolute;
    top: 5px;
  }
}

.staff-name-btn {
  .text-style {
    color: #347AF0;
    text-decoration: underline;
  }
}

.modal-wrapper-style {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  position: relative;
  outline: none;
  //box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.modal-header-style {
  width: 24px;
  right: 10px;
  position: absolute;
  padding: 8px 0;
  z-index: 10;
}

.modal-header-title {
  color: #4B5C68;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-header-sub {
  color: #172B4D;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.modal-body-wrapper {
  margin: 32px 0 12px;
  @media (min-width: 1919px) {
    margin: 32px 0 12px;
  }
}

.modal-body-wrapper-full {
  margin: 32px 0;
}

.modal-body-style {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
  padding: 24px;
  @media (min-width: 1919px) {
    padding: 32px;
  }
}


.modal-body-style-small {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
  padding: 24px;
}


.credential-item {
  display: flex;
  align-items: center;

  p {
    color: #4B5C68;
    font-size: 14px;
    font-weight: 400;
  }

  button {
    color: #A3B2BD;
    font-size: 14px;
    font-weight: 400;
    border: none;
    background: transparent;
  }
}

.not-set {
  color: #A3B2BD;
  font-size: 14px;
  font-weight: 400;
}

.credential-default-name {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.reset-btn {
  background: transparent;
  border: none;
}

.reset-btn-no-paddings {
  background: transparent;
  border: none;
  padding: 0;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.space-between-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.space-between-align-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.space-between-align-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.circle-box {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.green {
  background: #12B76A;
}

.yellow {
  background: #FFCA33;
}

.red {
  background: #F04438;
}

.button-reset {
  background: transparent;
  border: none;
}

.status-circle-text {
  color: #4B5C68;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2px;
}

.accordion-head-box {
  padding: 0 4px;
}

.accordion-head-wrapper {
  border-radius: 8px;
  background: #EBF2FD;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 6px #347af033;
}

.accordion-head-item {
  padding: 16px;
  color: #4B5C68;
  font-size: 14px;
  font-weight: 600;
}

.accordion-head-item-dark {
  padding: 16px;
  color: #4B5C68;
  font-size: 14px;
  font-weight: 600;
}

.accordion-body-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.accordion-body-item {
  padding: 8px 16px;
  color: rgba(34, 34, 34, 0.80);
  font-size: 14px;
  font-weight: 400;

  button {
    background: transparent;
    border: none;
    cursor: pointer;

  }

  .action-button-width {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    @media (min-width: 1919px) {
      margin-right: 16px;
    }
  }
}

.download-1500 {
  @include flexCenter;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 0;

  p {
    color: #347AF0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    //text-decoration-line: underline;
  }
}

.down-up-icon {
  transition: all .3s;
}


.open-sans {
  p, span, pre, a, h1, h2, h3, h4, h5, h6, div, * {
    font-family: Open Sans, sans-serif !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.space-between-flex-start {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-end {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.background-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.desktop {
  @media (min-width: $mobile) {
    display: none;
  }
  @media (min-width: $desktop) {
    display: block;
  }
}

.mobile {
  @media (min-width: $mobile) {
    display: block;
  }
  @media (min-width: $desktop) {
    display: none;
  }
}

.only-mobile {
  @media (min-width: $mobile) {
    display: block;
  }
  @media (min-width: $tablet) {
    display: none;
  }
}

.tablet {
  @media (min-width: $mobile) {
    display: none;
  }
  @media (min-width: $tablet) {
    display: block;
  }
  @media (min-width: $desktop) {
    display: none;
  }
}

.only-tablet {
  @media (min-width: $mobile) {
    display: none;
  }
  @media (min-width: $tablet) {
    display: block;
  }
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: $desktop) {
    padding: 0 40px;
  }
  @media (min-width: $tablet) {
    padding: 0 80px;
  }
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: $desktop) {
    padding: 0 40px;
  }
  @media (max-width: $tablet) {
    padding: 0 16px;
  }
}

.content-container-no-right {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 0 16px;
  @media (min-width: $tablet) {
    padding: 0 0 0 40px;
  }
  @media (min-width: $desktop) {
    padding: 0 0 0 80px;
  }
}

@mixin font-style($size, $weight, $color, $lineHeight) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $lineHeight;
}

@mixin overlay-style($zIndex) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex;
  width: 100%;
  height: 100vh;
}


.accordion-body-box {
  background: transparent;
  height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  scrollbar-width: none;

  //& ::-webkit-scrollbar {
  //  display: none!important;
  //  scrollbar-width: thin;
  //}

  .MuiAccordion-root.Mui-expanded {
    margin: 0 0 8px 0;
  }

  .MuiAccordion-root {
    background: #FFF;
    box-shadow: 0 3px 6px #347AF01A;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .MuiAccordionSummary-root:hover {
    background: #EBF2FD80;
    border-radius: 8px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordion-root:before {
    background: transparent;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    background: rgba(235, 242, 253, 0.50);
    border-bottom: 1px solid #FAFAFA;
    min-height: 50px;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: 50px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    padding: 16px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 2px;
}

.rdrCalendarWrapper {
  background: transparent;
  margin-top: 4px;
}

.rdrDateDisplayWrapper {
  background: transparent;
}

.rdrDefinedRangesWrapper {
  background: transparent;
}

.rdrStaticRangeSelected {
  border-radius: 8px 0 0 0;
}

.rdrDateRangePickerWrapper {
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  border-radius: 8px;
  background: white;
  padding-bottom: 20px;
}


.react-multiple-carousel__arrow--right {
  display: block;
  right: 0px;
}

.react-multiple-carousel__arrow--left {
  display: block;
  left: 0px;
}


.MuiIconButton-label:hover {
  background: transparent;
}

.export-csv-box {
  display: flex;
  align-items: center;
  gap: 16px;
}


.table-invoice-filter-section {
  @include spaceBetweenEnd;
  gap: 16px;
  .MuiAutocomplete-root {
    width: 170px !important;
    @media (min-width: 1919px) {
      width: 220px !important;
    }
  }

  .billing-invoices-filter-box {
    @include alignCenter;
    gap: 16px;

    .MuiOutlinedInput-root  {
      margin-top: 0!important;
    }
  }
}


.invoice-date-input {
  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #347AF0;
  }

  input {
    width: 145px;
    height: 36px;
    background: white;
    border: 1px solid rgba(163, 178, 189, 0.5);
    border-radius: 4px;
    padding: 2px 12px;
    font-weight: 400;
    font-size: 16px;
    color: #4B5C68;

    @media (min-width: 1919px) {
      width: 220px;
    }
  }
}

.tab-box-wrapper {
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(52, 122, 240, 0.20);
  @media (min-width: 1919px) {
    padding: 24px;
  }
}

.information-card-wrapper {
  display: flex;
  gap: 16px;
  @media (min-width: 1919px) {
    gap: 24px;
  }
}

.red-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $themeRed;
}

.close-btn-box {
  @include flexCenter;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0 18px;
  height: 48px;
  width: 100%;
  color: #4B5C68;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #F9FAFB;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.close-form-btn {
  position: absolute;
  right: -55px;
  @include resetBtn;

  img {
    width: 30px;
    height: 30px;
  }
}

.delete-btn-box {
  @include flexCenter;
  border-radius: 4px;
  border: 1px solid $themeRed;
  background: $themeRed;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 0 18px;
  height: 48px;
  width: 100%;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(254, 112, 112, 0.30);
  }
}

.confirm-btn {
  @include flexCenter;
  border-radius: 4px;
  border: 1px solid #347AF0;
  background: #347AF0;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 0 18px;
  height: 48px;
  width: 100%;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  //white-space: nowrap;

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
}

.filter-inputs-align-end {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.note-title-input {
  color: #4B5C68;
  font-size: 24px;
  font-weight: 700;
  border: none;
  outline: none;
  width: 100%;
  height: 33px;
  margin-bottom: 16px;
  background: transparent;

  &::placeholder {
    color: rgba(75, 92, 104, 0.40) !important;
  }
}

.show-eye-blue-icon {

  filter: invert(23%) sepia(95%) saturate(2342%) hue-rotate(208deg) brightness(96%) contrast(91%);

}

.tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  @media (min-width: 1919px) {
    margin-bottom: 18px;
  }
}

.table-action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


.router-accordion-box {
  .router-accordion-expand-icon {
    display: none;
  }

  & :hover {
    .router-accordion-expand-icon {
      display: block;
    }
  }
}

.create-chancel-custom-styles {
  & button {
    height: 36px !important;
  }
}

.detail-page-title-box {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #172B4D;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;

  p {
    font-weight: 600;
  }

  span {
    font-weight: 400;
  }

}

.detail-lists-style-wrapper {
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  padding: 16px;
}

.reset-btn-by-default {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.reset-btn {
  background: transparent;
  border: none;
  outline: none;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-text-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.show-tool-text-style {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}


.details-wrapper {
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  padding: 16px;
}

.details-list-box {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  width: 100%;
  font-family: "Open Sans", sans-serif;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 32%;
    max-width: 32%;
    padding: 9px 16px;
    background: #F2F4F8;
    border-radius: 8px;
    min-height: 36px;
    @media (max-width: 1280px) {
      padding: 9px 8px;
    }

    img {
      width: 18px;
      height: 18px;
      filter: invert(31%) sepia(84%) saturate(5840%) hue-rotate(209deg) brightness(98%) contrast(91%);
    }
  }

  .detail-title-style {
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  .detail-value-style {
    width: 100%;
    color: #172B4D;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.details-title-box {
  font-size: 18px;
  font-weight: 700;
  color: #51566D;
  text-transform: capitalize;
  margin-top: 24px;
}


.note-drawer-modal {
  & .MuiDrawer-paper {
    max-height: 595px;
    right: 0;
    bottom: 0;
    top: unset;
    width: 955px;
    border-radius: 8px;
    border: 1px solid rgba(52, 122, 240, 0.50);

    @media (min-width: 1919px) {
      right: 32px;
      bottom: 24px;
    }
  }
}


.generate-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1220px;
  height: 80vh;
  max-height: 800px;
  @media (min-width: 1919px) {
    width: 1652px;
  }

  .generate-claim-filter-section {
    .MuiAutocomplete-root {
      width: 220px !important;
    }
  }
}

.closed-status {
  font-size: 16px;
  color: #FE7070;
  font-weight: 600;
}
