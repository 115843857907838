@import "src/assets/styles/globalStyles";

.help-center-page {
  width: 100%;
  overflow: auto;
}

//ControlPanelPage

.help-center-page-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  background: $backgroundPrimary;


  .help-center-breadcrumbs {
    .page {
      color: #6B778C;
      font-family: "SF Pro Text", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &.last {
        color: #3D4751;
        text-align: center;
        font-family: "SF Pro Text", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .help-center-content {
    width: 78%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    margin: 63px 0 0 auto;
    padding: 40px 40px 80px 40px;

    @media (max-width: $desktop) {
      width: 70%;
      padding: 16px 16px 40px 16px;
    }

    @media (max-width: $tablet) {
      width: 100%;
      padding: 24px 16px 32px 16px;
    }

    .content-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      padding: 0 0 28px;

      @media (max-width: $tablet) {
        position: relative;
      }

      .MuiSpeedDial-root {
        position: fixed;
        top: 100px;
        right: 16px;
        bottom: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .MuiSpeedDial-actions {
          margin: -32px 0 0;
          padding: 48px 0 0;
        }
      }

      .icon-help-text {
        display: flex;
        align-items: center;
        gap: 8px;


        .MenuControlPanel-header-icon {
          min-width: 32px;
          min-height: 32px;

        }

        .text-help {
          color: $themePrimaryBold;
          font-family: Lexend, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .text-description {
        color: rgba(45, 67, 101, 0.70);
        font-family: Lexend, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .help-center-footer {
      width: fit-content;
      margin: 48px auto 0;

      .give-feedback {
        color: $textPrimary;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
      }

      .was-this {
        color: $textPrimary;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin: 4px 0 16px;
      }


      .feedback-svg-wrapper {
        width: fit-content;
        display: flex;
        gap: 16px;
        margin: 24px auto 0;

        .feedback-svg-line {
          height: auto;
          border-right: 1px solid #EFF2F6;
        }

        .feedback-svg-block {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #E9E9E9;
          background: transparent;
          cursor: pointer;

          &:hover {
            background: $backgroundSecondary15;
          }
        }
      }
    }
  }
}

//MenuControlPanel

.menu-control-panel-wrapper {
  height: 100%;
  width: 22%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-right: 1px solid $backgroundSecondary14;

  @media (max-width: $desktop) {
    width: 30%;

    @media (max-width: 767px) {
      width: 250px;
      padding: 0;
      position: revert;
      border-radius: 4px;
      background: #FFF;
      box-shadow: 13px 10px 33px 0 rgba(122, 122, 122, 0.30);
      z-index: 999999999999;
    }
  }

  .form-control-long-short {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 0 12px;

    @media (max-width: $desktop) {
      margin: 0;
    }

    .long-short-label {
      color: $titleColor13;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      @media (max-width: $desktop) {
        font-size: 14px;
        line-height: 20px;
      }

      span {
        color: $titleColor14;
      }
    }
  }

  .menu-control-panel-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 103px 24px 80px 24px;
    background: $backgroundSecondary7;
    overflow: scroll;

    //@media (max-width: $desktop) {
    //  padding: 32px 40px 32px 40px;
    //}

    @media (max-width: $tablet) {
      padding: 10px 16px;
      max-height: 527px;
      overflow-y: auto;
    }

    .search-input-wrapper {
      margin-bottom: 0;


      .user-input-label {
        .input-box {
          border: none !important;
          background: white;
          padding: 9px 12px !important;

          input {
            font-family: Inter, sans-serif !important;
            font-size: 11.767px !important;
            font-weight: 400 !important;
            line-height: 17.651px !important;
          }
        }
      }

    }

    .menu-help-center-header {
      cursor: pointer;

      .icon-help-text {
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: $desktop) {
          gap: 8px;
        }

        .menu-help-center-header-icon {
          min-width: 32px;
          min-height: 32px;

          @media (max-width: $desktop) {
            min-width: 24px;
            min-height: 24px;
          }
        }

        .text-help {
          color: $themePrimaryBold;
          font-family: Lexend, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .text-description {
        color: rgba(45, 67, 101, 0.70);
        font-family: Lexend, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-left: 48px;

        @media (max-width: $desktop) {
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }

    .menu-items-wrapper {
      display: flex;
      flex-direction: column;

      .menu-items-box {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        border-bottom: 1px solid $backgroundSecondary14;


        .menu-items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 4px;
          cursor: pointer;


          &.active-menu-item {
            .menu-item_svg {
              transform: rotate(0);

              path {
                fill: $themePrimaryBold;
              }
            }

            .menu-item_svg_noRotate {
              transform: rotate(0);
            }

            .menu-item_text {
              color: $themePrimaryBold;
              font-weight: 700;
            }
          }

          &.active_open_menu {
            .menu-item_svg {
              transform: rotate(90deg);
            }

            .menu-item_svg_noRotate {
              transform: rotate(0);
            }

          }

          &:hover {
            .menu-item_svg {
              path {
                fill: $themePrimaryBold;
              }
            }

            .menu-item_text {
              color: $themePrimaryBold;
            }
          }

          .menu-item_text {
            color: $textWhit4;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;


            &.text-hovered {
              color: $themePrimaryBold;
            }
          }
        }
      }

      .menu-sub-items-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        margin-left: 12px;

        .menu-sub-items-block {
          @include flexCenter;
          justify-content: space-between;
          cursor: pointer;

          .menu-sub-items-text {
            color: $textWhit5;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;

            @media (max-width: $desktop) {
              font-size: 14px;
              line-height: 21px;
            }

            &.active-sub-menu-item {
              color: $themePrimaryBold;
            }
          }

          &:hover {
            .menu-sub-items-text {
              color: $themePrimaryBold;
            }

            .menu-item_svg {
              path {
                fill: $themePrimaryBold;
              }
            }
          }

          &.menu-sub-items-block-open {
            .menu-item_svg {
              transform: rotate(90deg);
            }
          }

          &.menu-sub-items-block-active {
            .menu-item_svg {
              path {
                fill: $themePrimaryBold;
              }
            }
          }
        }

        .menu_sublist_block {
          margin: 12px 12px 0;
          border-left: 2px solid $backgroundSecondary17;

          .menu_sublist {
            width: 100%;
            color: $textWhit4;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 4px 0 4px 16px;
            cursor: pointer;

            &:hover {
              color: $titleColor12;
              font-weight: 400;
            }

            &.active_menu_sublist {
              color: $titleColor12;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}


.help-center-content-box {
  font-family: Inter, sans-serif !important;
  max-width: 912px;

  .help-center-header-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;

    .help-center-info-title {
      color: $textPrimary;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-weight: 700;
    }

    .help-center-info-description {
      color: $textPrimary;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .help-center-content-title {
    color: $textWhit4;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 32px;
  }


  .help-center-content-subTitle {
    color: $textPrimary;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .help-center-content-description {
    color: $textPrimary;
    font-family: Inter, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

  }

  .flex-gap-32 {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .flex-gap-8 {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .padding-18 {
    padding: 0 18px;
  }

  .padding-30 {
    padding: 0 30px;
  }

  .list-style-type-decimal {
    list-style-type: decimal;
    margin-left: 20px;
  }

  .list-style-type-circle {
    list-style-type: disc;
    margin-left: 20px;

    li::marker {
      color: #333;
      font-size: 18px;
    }
  }

  .list-style-type-none {
    list-style-type: none;
  }

  .image-block {
    width: 100%;
    height: 431px;
    margin-left: -20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .image-block-m0 {
    margin: 0;
  }

  .notes_block {
    display: flex;
    align-items: start;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $backgroundSecondary13;
    background: $backgroundSecondary16;

    .help-center-notes-svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

}
