@import "src/assets/styles/globalStyles";

//AboutUs page
.about-us-page {
  padding-top: 176px;
  background: white;

  @media (max-width: $desktop) {
    padding-top: 144px;
  }

  @media (max-width: $tablet) {
    padding-top: 100px;
  }


  .about-us-title {
    max-width: 1079px;
    color: $titleColor;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 40px;

    @media (max-width: $desktop) {
      max-width: 601px;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 32px;
    }

    @media (max-width: $tablet) {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }

  .about-us-banner-images-wrapper {
    position: relative;
    width: 100%;
    max-width: 1280px;
    aspect-ratio: 1280/583;
    display: flex;
    gap: 20px;

    .about-us-banner-1 {
      max-width: 892px;
      height: 100%;
      aspect-ratio: 892/583;
      background: no-repeat url("/assets/images/landingPage/aboutUs/banner1hovered.jpg");
      background-size: 100% 100%;
      border-radius: 10px;
      overflow: hidden;


      &:hover {
        background: no-repeat url("/assets/images/landingPage/aboutUs/banner1.jpg");
        background-size: cover;
      }

    }

    .about-us-banner-2 {
      max-width: 356px;
      height: 100%;
      aspect-ratio: 356/583;
      background: no-repeat url("/assets/images/landingPage/aboutUs/banner2hovered.jpg");
      background-size: 100% 100%;
      border-radius: 10px;
      overflow: hidden;


      &:hover {
        background: no-repeat url("/assets/images/landingPage/aboutUs/banner2.png");
        background-size: cover;
      }
    }

    .about-us-banner-3 {
      position: absolute;
      bottom: 8%;
      right: 12%;
      max-width: 494px;
      height: 35%;
      aspect-ratio: 494/204;
      background: no-repeat url("/assets/images/landingPage/aboutUs/banner3.svg");
      background-size: 100% 100%;
    }



  }

  .about-us-cards-block {
    background: $backgroundSecondary7;

    .about-us-cards {
      display: flex;
      padding: 56px 0;
      gap: 32px;

      @media (max-width: $desktop) {
        flex-direction: column;
        padding: 40px 0;
        gap: 24px;
      }
    }
  }


  //Core Values

  .core-values-wrapper {
    background: $backgroundSecondary5;

    .core-values-box {
      padding: 80px 48px;

      @media (max-width: $desktop) {
        padding: 64px 0;
      }

      @media (max-width: $tablet) {
        padding: 40px 0;
      }

      .core-values-toolTip {
        width: fit-content;
        color: $titleColor8;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 24px;
        padding: 4px 12px;
        background: $backgroundSecondary2;
        margin: 0 auto;

        @media (max-width: $tablet) {
          font-size: 12px;
          line-height: 18px;
          padding: 2px 8px;
        }
      }

      .core-values-title {
        color: $titleColor;
        text-align: center;
        font-size: 46px;
        font-weight: 700;
        line-height: 56px;
        margin: 16px 0 24px;

        @media (max-width: $desktop) {
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          margin: 12px 0 56px;
        }

        @media (max-width: $tablet) {
          font-size: 26px;
          line-height: 36px;
          margin: 8px 0 32px;
        }
      }

      .core-values-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        .core-values-card-item {
          width: calc(100% / 2 - 16px);

          @media (max-width: $desktop) {
            width: 100%;
            gap: 24px;
          }

          @media (max-width: $tablet) {
            gap: 16px;
          }
        }
      }
    }
  }

  //TheMission
  .the-mission-wrapper {
    width: 100%;
    background: $backgroundSecondary5;
    padding: 80px 0;

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    @media (max-width: $tablet) {
      padding: 40px 0;
    }

    .the-mission-box {
      display: flex;
      align-items: center;
      gap: 104px;

      @media (max-width: $desktop) {
        flex-direction: column;
        gap: 24px;
      }

      .the-mission-content {
        width: 100%;
        max-width: 449px;

        @media (max-width: $desktop) {
          max-width: 100%;
        }

        .the-mission-title {
          color: $titleColor;
          font-size: 46px;
          font-weight: 700;
          line-height: 58px;

          @media (max-width: $desktop) {
            font-size: 32px;
            line-height: 44px;
          }

          @media (max-width: $tablet) {
            font-size: 28px;
            line-height: 40px;
          }
        }

        .the-mission-sub_title {
          color: $titleColor3;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin: 24px 0 16px;

          @media (max-width: $desktop) {
            margin: 12px 0;
          }

          @media (max-width: $tablet) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .the-mission-description {
          color: $titleColor3;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .the-mission-image-block {
        max-width: 100%;
        aspect-ratio: 883/445;

        @media (max-width: $desktop) {
          aspect-ratio: 688/363;
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  //GER IN TOUCH

  .get-in-touch-wrapper {
    width: 100%;

    .get-in-touch-box {
      position: relative;
      max-width: 1032px;
      aspect-ratio: 1032/341;
      background: $themePrimaryBold;
      border-radius: 8px;
      margin: 0 auto -64px;
      padding: 64px 0;
      z-index: 3;

      @media (max-width: $desktop) {
        margin: 0 auto -43px;

      }

      @media (max-width: $tablet) {
        padding: 32px 24px;
        margin: 0 auto -40px;

      }

      @media (max-width: 375px) {
        padding: 24px;
      }

      &:hover {
        background: url("/assets/images/landingPage/footer/footerBeck.png") $themePrimaryBold  -336.109px -672.977px / 173.02% 531.343% no-repeat;
      }

      .get-in-touch-title {
        color: $backgroundSecondary5;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        line-height: 120%;

        @media (max-width: $desktop) {
          font-size: 28px;
        }

        @media (max-width: $tablet) {
          font-size: 24px;
        }

      }

      .get-in-touch-description {
        max-width: 608px;
        color: $textWhit3;
        font-size: 18px;
        font-weight: 500;
        line-height: 160%;
        text-align: center;
        margin: 16px auto 40px;

        @media (max-width: $desktop) {
          font-size: 16px;
          margin: 16px auto 32px;
        }

        @media (max-width: $tablet) {
          line-height: 22px;
          margin: 16px auto 24px;
        }
      }

      .get-in-touch-button {
        background: $backgroundButton;
        padding: 16px 24px;
        margin: 0 auto;
      }
    }
  }
}

//AboutUsCard
.about-us-card-wrapper {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  font-family: Inter, sans-serif;


  .about-us-card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 24px 10px;
    background: $backgroundSecondary9;

    @media (max-width: $desktop) {
      padding: 16px 10px;
    }

    @media (max-width: $tablet) {
      justify-content: flex-start;
      gap: 12px;
      padding: 12px 16px;
    }

    .about-us-card-icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;

      @media (max-width: $desktop) {
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
      }

      @media (max-width: $tablet) {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
      }
    }

    .core-card-values-title {
      color: $titleColor3;
      font-size: 24px;
      font-weight: 600;

      @media (max-width: $desktop) {
        font-size: 20px;
      }

      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }
  }

  .about-us-card-body {
    padding: 32px;
    background: $backgroundSecondary5;

    @media (max-width: $desktop) {
      padding: 24px;
    }

    @media (max-width: $tablet) {
      padding: 16px;
    }

    .about-us-card-description {
      color: $titleColor5;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;

      @media (max-width: $desktop) {
        font-size: 16px;
        line-height: 24px;
      }

      @media (max-width: $tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

//CoreValuesCard

.core-values-card-wrapper {
  width: 100%;
  display: flex;
  gap: 24px;
  border-radius: 8px;
  overflow: hidden;
  padding: 32px;
  background: $backgroundSecondary7;
  cursor: pointer;

  @media (max-width: $desktop) {
    padding: 24px;
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 16px;
  }

  &:hover {
    background: $backgroundButton;

    .core-values-card-icon {
      path {
        fill: $backgroundSecondary5;
      }
    }

    .core-values-title-description {
      .core-card-values-title, .core-card-values-description {
        color: $backgroundSecondary5;
      }
    }
  }

  .core-values-card-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;


    @media (max-width: $tablet) {
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
    }

    path {
      fill: $backgroundOrange;
    }
  }

  .core-values-title-description {
    display: flex;
    flex-direction: column;
    gap: 9px;

    @media (max-width: $tablet) {
      gap: 8px;
    }

    .core-card-values-title {
      color: $titleColor;
      font-size: 24px;
      font-weight: 600;


      @media (max-width: $tablet) {
        font-size: 20px;
      }

    }

    .core-card-values-description {
      color: $titleColor3;
      font-size: 16px;
      font-weight: 400;
      line-height: 157.4%;

    }
  }
}