@import "src/assets/styles/globalStyles";

.info-and-image {
  display: flex;
  align-items: center;
  width: 100%;

  &.client {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.ACTIVE::before {
      background-color: #6FD231
    }

    &.INACTIVE::before {
      background-color: #4B5C68
    }

    &.HOLD::before {
      background-color: #347AF0
    }

    &.TERMINATED::before {
      background-color: #FE7070
    }

    &.ON_HOLD::before {
      background-color: #FFCA33
    }

    &.FBA::before {
      background-color: #ADD8E6
    }

    &.WAIT_LIST::before {
      background-color: #FE7B43
    }

    &.GRADUATED::before {
      background-color: #51439E
    }

    img {
      margin-left: 24px;
      @media (max-width: 1280px) {
        margin-left: 8px;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: #4B5C68;
  }

  span {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: #4B5C68;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    @media (min-width: 1920px) {
      margin-right: 8px;
    }
  }
}

.info-and-avatar {
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: #4B5C68;
  }

  span {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: #4B5C68;
  }

  .avatar-box {
    border: 3px solid #EBF2FD;
    background: #F9FBFF;
    border-radius: 40px;
    color: #0C66E4;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-right: 8px;
    height: 38px;
    width: 38px;
    min-width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 32px;
      width: 32px;
      object-fit: cover;
      border-radius: 40px;
    }

    @media (min-width: 1920px) {
      margin-right: 8px;
    }
  }

}

.document-table-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.dock-table-wrapper {
  width: 100%;
  padding: 0 4px;
}

.document-upload {
  max-width: 511px;
  width: auto;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(52, 122, 240, 0.3);
  border-radius: 8px;
  padding: 24px 32px;
  margin-right: 24px;
  margin-top: 24px;
}

.upload-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #4B5C68;
  margin-bottom: 24px;
}

.drag-drop-component {
  height: 150px;
  border-radius: 8px;
  border: 1px dashed #A9B7C1;
  background: #F9FAFB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input[type='file'] {
    display: none;
  }

  img {
    margin-bottom: 12px;
  }

  .upload {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #172B4D;
    cursor: pointer;
  }
}


.item-loading {
  margin-top: 16px;
}

.loaded-documents-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  width: 100%;
  position: relative;
}

.loaded-document-info-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.loaded-document-name {

  p {
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.loaded-document-field {
  width: 100%;

  input {
    width: 100%;
    height: 36px;
    padding: 0 12px;
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.edit-action-btn {
  @include flexCenter;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #F9FAFB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0;
}


.loaded-document-detail-box {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}

.full-loaded {
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.loaded-circle {
  width: 3px;
  height: 3px;
  background: #98A2B3;
  border-radius: 50%;
}

.images-info {
  margin-top: 16px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4B5C68;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #A3B2BD;
  }

  img {
    margin-right: 8px;
  }
}

.remove-button {
  background: transparent;
  border: none;
  position: absolute;
  right: 16px;
  top: 16px;
}

.document-actions {
  display: flex;
  align-items: baseline;
  gap: 8px;

  button, a {
    background: transparent;
    border: none;
    width: 40px;
    height: 25px;

    img {
      filter: brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(701%) hue-rotate(187deg) brightness(101%) contrast(94%);
    }
  }
}

.select-document-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
  margin-top: 8px;
}

.dock-info-wrapper {
  display: flex;
  align-items: center;
}

.file-type {
  width: auto;
  min-width: 39px;
  height: 20px;
  padding: 2px 8px;
  background: rgba(52, 122, 240, 0.1);
  border-radius: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #347AF0;
  display: flex;
  justify-content: center;
}

.file-size {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #A3B2BD;
  margin-left: 16px;
}

.file-type-box {
  display: flex;
  padding: 2px 4px;
  color: #FFF;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  width: fit-content;
  line-height: 19px;
}

.full-loaded-line {
  height: 8px;
  width: 100%;
  background: #347AF0;
  border-radius: 4px;
}


@mixin boxStyle {
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  min-height: 50px;
}

.green-zone-row {
  background: #ECFDF3;
  @include boxStyle;
}

.yellow-zone-row {
  background: #FFF6ED;
  @include boxStyle;
}

.red-zone-row {
  background: #FEF3F2;
  @include boxStyle;
}

.title-text {
  width: 100%;
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.subtitle-text {
  width: 100%;

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}


@-webkit-keyframes slideUp {
  from {
    bottom: -100px;
  }

  to {
    bottom: 50px;
  }
}

@keyframes slideUp {
  from {
    bottom: -100px;
  }

  to {
    bottom: 50px;
  }
}


.select-action-box {
  -webkit-animation: slideUp 1s forwards;
  animation: slideUp 1s forwards;
  position: fixed;
  z-index: 10;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #0948A1;
  background: #172B4D;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  height: 48px;
  padding: 6px 12px;
  font-family: "Open Sans", sans-serif !important;

  .select-count-box {
    display: flex;
    align-items: center;
    gap: 4px;
    border-right: 1px solid #E4E7EC;

    div {
      border-radius: 2px;
      background: #F9FAFB;
      padding: 2px 4px;
      height: 20px;
      color: #172B4D;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }

    p {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      padding-right: 12px;
    }
  }

  .select-all-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .submit-btn-box {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
    padding: 6px 12px;
    gap: 4px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    margin-right: 16px;
  }

  .close-selected-btn{
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
    border: none;
  }
}
