@import "src/assets/styles/globalStyles";

@mixin card-border {
  border-left: 1px solid #edf8ff;
  border-right: 1px solid #edf8ff;
  @media (max-width: $mobile) {
    border-left: none;
    border-right: none;
  }
}

@mixin slider-button-style($bgImage) {
  width: 34px !important;
  height: 18px !important;
  background-color: inherit !important;
  background-image: url($bgImage) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  cursor: pointer;
  &:disabled {
    cursor: default !important;
  }
  @media (max-width: $tablet) {
    width: 32px !important;
  }
  @media (max-width: $mobile) {
    width: 18px !important;
  }
}

.home-fragment {
  width: 100%;
  background: $backgroundSecondary5;

  //FIRST BLOCK
  .empowering-practices-wrapper {
    width: 100%;
    background: $backgroundPrimaryGradient;

    .empowering-practices-block {
      width: 100%;
      //max-width: 1440px;
      padding: 160px 0;
      margin: 0 auto;

      @media (max-width: $desktop) {
        padding: 144px 0;
      }

      @media (max-width: $tablet) {
        padding: 124px 0;
      }

      .empowering-practices-container {
        width: 100%;
        display: flex;
        justify-content: center;
        //max-width: 1360px;
        //margin-left: auto;

        //padding-left: 80px;

        @media (max-width: 1380px) {
          padding-left: 80px;
        }

        @media (max-width: $desktop) {
          padding-left: 40px;
        }

        @media (max-width: $tablet) {
          padding-left: 16px;
        }

        .empowering-practices-box {
          width: 100%;
          max-width: 1440px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 64px;


          @media (max-width: $desktop) {
            flex-direction: column;
            align-items: start;
            gap: 40px;
          }

          .empowering-practices-content {
            max-width: 674px;

            .content-title {
              color: $titleColor;
              font-family: Inter, sans-serif;
              font-size: 36px;
              font-style: normal;
              font-weight: 600;
              line-height: 44px;
              letter-spacing: -0.72px;

              @media (max-width: $desktop) {
                font-size: 32px;
              }

              @media (max-width: $tablet) {
                font-size: 22px;
              }
            }

            .content-subtitle {
              color: $titleColor3;
              font-family: Inter, sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;


              @media (max-width: $desktop) {
                font-size: 16px;
              }
            }

            .content-subtitle_margin {
              margin: 20px 0 48px;

              @media (max-width: $desktop) {
                margin: 16px 0 32px;
              }
            }

            .content-buttons-block {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 32px;

              @media (max-width: $tablet) {
                flex-direction: column;
                align-items: start;
                gap: 16px;
              }

              .learn-more-button {
                &:hover {
                  .play-svg {
                    path {
                      stroke: $themePrimaryBold;
                    }
                  }
                }
              }

              .request-demo-btn, .learn-more-button {
                @media (max-width: $tablet) {
                  width: 100%;
                  max-width: 343px;
                }
              }
            }
          }

          .empowering-practices-image-block {
            display: flex;
            justify-content: flex-end;
            max-width: 620px;
            aspect-ratio: 620/429;
            align-self: end;

            @media (max-width: $desktop) {
              max-width: 641px;
              aspect-ratio: 641/425;;
            }

            @media (max-width: 374px) {
              max-width: 303px;
              aspect-ratio: 303/273;;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

    }
  }

  //SECOND BLOCK
  .features-wrapper {
    width: 100%;
    background: $backgroundSecondary5;


    .features-block {
      //max-width: 1440px;
      margin: 0 auto;
      padding: 80px 0;
      background: url("/assets/images/landingPage/landingImages/backInfinityFeatures.png") no-repeat right top;

      @media (max-width: $desktop) {
        padding: 64px 0;
      }

      @media (max-width: $tablet) {
        width: 100%;
        padding: 16px 0;
      }


      .features-block-back {
        position: absolute;
        top: -80px;
        right: 0;
        background: $backgroundSecondary5;
        z-index: 1;

        @media (max-width: $desktop) {
          display: none;
        }


        img {
          width: 100%;
          height: 100%;
        }
      }

      .features-box {
        //position: relative;
        //z-index: 2;

        .lorem-button {
          width: fit-content;
          border-radius: 24px;
          background: $backgroundSecondary2;
          color: $titleColor3;
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          padding: 4px 12px;
        }

        .title-button-block {
          display: flex;
          justify-content: space-between;
          margin: 4px 0 56px;

          @media (max-width: $tablet) {
            margin: 4px 0 40px;
          }

          .content-title {
            color: $titleColor;
            font-family: Inter, sans-serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;

            @media (max-width: $desktop) {
              font-size: 32px;
              line-height: 48px;
            }

            @media (max-width: $tablet) {
              font-size: 24px;
              line-height: 36px;
            }
          }
        }


        .features-list-box {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;

          @media (max-width: $tablet) {
            gap: 12px;
          }

          .feature-card-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% / 4 - 18px);
            border-radius: 4px;
            border: 1px solid $textWhit2;
            background: $textWhit3;
            padding: 20px;
            transition: all .2s;

            @keyframes show-overflow {
              from {
                overflow: hidden;
              }
            }

            @media (max-width: $desktop) {
              width: calc(100% / 2 - 18px);
            }

            @media (max-width: $tablet) {
              width: calc(100%);
              max-height: 66px;
              padding: 16px;
              overflow: hidden;
              transition: all .2s;
              animation: show-overflow .6s;
            }

            &:hover {
              background: $themePrimaryBold;
              border: 1px solid $themePrimaryBold;
              transition: all .2s;

              @media (max-width: $tablet) {
                max-height: 200px;
                overflow: auto;
                animation: show-overflow .6s;
                transition: all .5s;
              }

              .feature-card-svg {
                transition: all .2s;

                path {
                  transition: all .2s;
                  fill: $textWhite
                }
              }

              .feature-card-title, .feature-card-description {
                transition: all .2s;
                color: $textWhite;
              }

              .feature-card-arrow-block {
                transition: all .2s;
                opacity: 1;
              }
            }


            .feature-card-box {

              .feature-icon-text {
                @media (max-width: $tablet) {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                }
              }

            }

            .feature-card-svg {
              transition: all .2s;

              @media (max-width: $tablet) {
                width: 32px;
                height: 32px;
              }

              path {
                transition: all .2s;
                fill: $themePrimaryBold
              }
            }

            .feature-card-title {
              color: $titleColor;
              font-family: Inter, sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              transition: all .2s;
              margin: 32px 0 12px;

              @media (max-width: $tablet) {
                font-size: 20px;
                margin: 0;
              }

            }

            .feature-card-description {
              color: $titleColor3;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              transition: all .2s;

              @media (max-width: $tablet) {
                font-size: 16px;
                margin: 12px 0;
              }
            }

            .feature-card-arrow-block {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: $backgroundSecondary3;
              cursor: pointer;
              margin-left: auto;
              margin-top: 10px;
              opacity: 0;
              transition: all .2s;

              @media (max-width: $tablet) {
                margin-top: 0;
              }

              .feature-card-arrow {
                width: 23px;
                height: 23px;
                transform: rotate(-45deg);
                transition: all .2s;

                path {
                  stroke: $themePrimaryBold;
                }
              }

              &:hover {
                .feature-card-arrow {
                  transform: rotate(0deg);

                }
              }
            }
          }
        }

        .everything-button-mobile {
          margin: 40px auto 0;
        }
      }
    }
  }

  //THIRD BLOCK
  .why-therapy-wrapper {
    width: 100%;
    background: $textWhit3;

    .why-therapy-box {
      width: 100%;
      max-width: 1440px;
      padding: 80px 0;
      margin: 0 auto;

      @media (max-width: $desktop) {
        padding: 64px 0;
      }

      @media (max-width: $tablet) {
        padding: 64px 0;
      }

      .why-therapy-container {
        width: 100%;
        max-width: 1360px;
        margin-left: auto;

        @media (max-width: 1380px) {
          padding-left: 80px;
        }

        @media (max-width: $desktop) {
          padding: 0 40px;
        }

        @media (max-width: $tablet) {
          padding: 0 16px;
        }

        .lorem-ipsum-button {
          width: fit-content;
          border-radius: 24px;
          background: $backgroundSecondary2;
          color: $titleColor3;
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          padding: 4px 12px;
          margin: 0 auto;
        }

        .why-therapy-title {
          color: $titleColor;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin: 16px 0;


          @media (max-width: $desktop) {
            font-size: 32px;
          }

          @media (max-width: $tablet) {
            font-size: 24px;
          }
        }

        .why-therapy-description {
          color: $titleColor3;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          text-align: center;
          margin: 0 0 56px;

          @media (max-width: $desktop) {
            font-size: 16px;
            margin: 0 0 40px;
          }
        }

        .observer {
          width: 100%;
          position: static;
          height: fit-content;
        }

        .why-therapy-content {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 40px;

          @media (max-width: $desktop) {
            flex-direction: column;
            gap: 32px;
          }

          .why-therapy-content_left-side {
            max-width: 33%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media (max-width: $desktop) {
              max-width: 100%;
            }
          }

          .why-therapy-content_right-side {
            max-width: 67%;
            width: 100%;
            aspect-ratio: 889/472;

            @media (max-width: $desktop) {
              max-width: 618px;
              width: 100%;
              aspect-ratio: 618/290;;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  //TechnologicalInnovations

  .technological-wrapper {
    width: 100%;
    background: $backgroundButton;

    .technological-block {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      padding: 80px 0;

      @media (max-width: $desktop) {
        padding: 64px 0;
      }

      @media (max-width: $tablet) {
        padding: 56px 0;
      }

      .technological-container {
        max-width: 1380px;
        width: 100%;
        margin-right: auto;

        @media (max-width: $desktop) {
          max-width: 100%;
          padding: 0 40px;
          margin: 0;
        }

        @media (max-width: $tablet) {
          padding: 0 16px;
        }

        .technological-box {
          display: flex;
          align-items: flex-start;
          gap: 80px;

          @media (max-width: $desktop) {
            flex-direction: column-reverse;
            align-items: center;
            gap: 40px;
          }

          @media (max-width: $tablet) {

          }

          .technological-image-block {
            max-width: 767px;
            aspect-ratio: 767/328;

            @media (max-width: $desktop) {
              max-width: 688px;
              aspect-ratio: 688/294;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .technological-content {
            max-width: 481px;

            @media (max-width: $desktop) {
              max-width: 100%;
            }

            @media (max-width: $tablet) {

            }


            .technological-title {
              color: $backgroundSecondary5;
              font-family: Inter, sans-serif;
              font-size: 36px;
              font-weight: 600;
              line-height: 120%;

              @media (max-width: $tablet) {
                font-size: 32px;
                font-weight: 700;
              }
            }

            .technological-desc {
              color: $backgroundSecondary5;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 180%;
              margin: 12px 0 24px;

              @media (max-width: $tablet) {
                font-size: 16px;
                font-weight: 50;
                margin: 16px 0 24px;
              }
            }

            .technological-checkbox-wrapper {
              display: flex;
              flex-direction: column;
              gap: 16px;

              @media (max-width: $tablet) {
                gap: 24px;
              }

              .technological-checkbox-block {
                display: flex;
                align-items: center;
                gap: 8px;

                .technological-checkbox-text {
                  color: $textWhit3;
                  font-family: Inter, sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20px;
                }
              }
            }

            .technological-button {
              width: 100%;
              max-width: 207px;
              margin-top: 40px;

              @media (max-width: $desktop) {
                display: none;
              }
            }
          }

          .technological-button-mobile {
            width: 100%;
            max-width: 280px;
            display: none;
            @media (max-width: $desktop) {
              display: block;
            }
          }
        }
      }
    }
  }

  //CustomerTestimonials

  .customer-testimonials-wrapper {
    padding: 64px 0;
    background: $backgroundSecondary6;

    @media (max-width: $tablet) {
      padding: 40px 0;
    }

    .customer-testimonials-box {
      .what-customers-say {
        width: fit-content;
        color: $titleColor3;
        font-family: Inter, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        border-radius: 24px;
        background: $backgroundSecondary2;
        padding: 4px 12px;
        margin: 0 auto;
      }

      .customer-testimonials-title {
        color: $titleColor;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin: 12px 0 64px;

        @media (max-width: $desktop) {
          font-size: 28px;
          line-height: 36px;
          margin: 12px 0 40px;
        }

        @media (max-width: $tablet) {
          padding: 40px 0;
        }
      }

      .customer-carousel-wrapper {

        .slider-control-bottomcenter {
          display: flex !important;
          justify-content: center !important;
          position: static !important;
          transform: translate(0, 0) !important;
          margin: 0 auto !important;
        }

        .slider-slide {
          flex: 1; /* Распределяем пространство равномерно между слайдами */
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .render-carousel-center-control {
          display: flex;
          align-items: center;
          margin-top: 40px;
          gap: 24px;

          .carousel-left-button, .carousel-right-button {
            cursor: pointer;

            &:hover {
              opacity: .8;
            }
          }

          .carousel-dots-wrapper {
            display: flex;
            gap: 8px;

            .carousel-dots {
              min-width: 8px;
              max-width: 8px;
              min-height: 8px;
              max-height: 8px;
              border-radius: 50%;
              border: none;
              background: #D7D6DE;
              cursor: pointer;

              &.active-dots {
                background: #101214;
              }
            }
          }
        }
      }
    }
  }


  //AccessKey
  .access-key-wrapper {
    padding: 80px 0;

    @media (max-width: $tablet) {
      padding: 64px 0;
    }

    .access-key-block-box {
      display: flex;
      gap: 200px;

      @media (max-width: $desktop) {
        flex-direction: column;
        gap: 40px;
      }

      .access-key-left-box {
        max-width: 618px;

        @media (max-width: $desktop) {
          max-width: 100%;
        }

        .mobile-app {
          width: fit-content;
          color: $titleColor3;
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          padding: 4px 12px;
          background: #F5F5F5;
          border-radius: 16px;
        }

        .access-key-title {
          max-width: 543px;
          color: $titleColor;
          font-family: Inter, sans-serif;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin: 16px 0 20px;


          @media (max-width: $desktop) {
            max-width: 100%;
            font-size: 32px;
            line-height: 48px;
            margin: 16px 0;
          }

          @media (max-width: $tablet) {
            font-size: 28px;
            line-height: 42px;
          }
        }

        .access-key-description {
          color: #535862;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

        }

        .access-key-content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin: 16px 0 40px;

          @media (max-width: $desktop) {
            gap: 16px;
          }

          @media (max-width: $tablet) {
            margin: 16px 0 32px;
          }

          .access-key-content {
            gap: 8px;

            .access-key-svg {
              min-width: 24px;
              min-height: 24px;
            }

            .access-key-content-text {
              color: $titleColor3;
              font-family: Inter, sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
            .access-key-content-desc{
              color: #535862;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-top: 8px;
              margin-left: 40px;
            }
          }
        }

        .gPlay-aStore-block {
          display: flex;
          gap: 24px;

          @media (max-width: $tablet) {
            flex-direction: column;
            gap: 16px;
          }


          .google-play {
            width: 181px;
            height: 48px;
            cursor: pointer;

            @media (max-width: $desktop) {
              max-width: 240px;
            }

            @media (max-width: $tablet) {
              max-width: 343px;
              height: 56px;
            }

            img {
              width: 100%;
              height: 100%;
            }

            &:hover {
              opacity: .8;
            }
          }

          .app-store {
            width: 181px;
            height: 48px;
            cursor: pointer;

            @media (max-width: $desktop) {
              max-width: 240px;
            }

            @media (max-width: $tablet) {
              max-width: 343px;
              height: 56px;
            }

            &:hover {
              opacity: .8;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .access-key-imgBlock {
        max-width: 346px;
        aspect-ratio: 346/346;
        margin: 0 auto;

        @media (min-width: $tablet) {
          max-width: 688px;
          aspect-ratio: 688/720;
        }
        @media (min-width: $desktop) {
          max-width: 650px;
          aspect-ratio: 650/782;
          margin-right: -70px;
          //
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}


@keyframes show-overflow {
  from {
    overflow: hidden;
  }
}

//WHY THERAPY CARD

.why-therapy-card-wrapper {
  width: 100%;
  position: relative;
  padding: 16px;
  cursor: pointer;

  @media (max-width: $tablet) {
    max-height: 52px;
    transition: max-height .3s;
    overflow: hidden;
  }

  .card-svg-block {
    display: flex;
    align-items: center;
    gap: 8px;


    .why-therapy-card-title {
      color: $titleColor4;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 120%;

      @media (max-width: $tablet) {
        font-size: 18px;
      }
    }

    .why-therapy-card-svg {
      path {
      }
    }
  }

  .why-therapy-card-description {
    max-height: 338px;
    color: $titleColor3;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 6px;

    @media (max-width: $desktop) {
      max-height: 100%;
      margin-top: 8px;
    }
  }


  .loading-bar {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $backgroundSecondary4;
  }


  &.active-card {
    background: $backgroundSecondary5;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(35, 40, 105, 0.08);

    @media (max-width: $tablet) {
      max-height: 500px;
      animation: show-overflow .5s;
      transition: all .5s;
    }

    .card-svg-block {

      .why-therapy-card-title {
        color: $titleColor4;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;

        @media (max-width: $tablet) {
          font-size: 18px;
        }

      }

      .why-therapy-card-svg {
        path {
          fill: $titleColor4;
        }
      }
    }

    .loading-bar {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $backgroundSecondary4;
    }

    .progress {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 11;
      background: $backgroundOrange;
      animation: load 5s;
    }

    @keyframes load {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }
  }
}

//CustomerCard

.customer-card-wrapper {
  width: 100%;
  min-height: 306px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid $backgroundBorder;
  background: $backgroundSecondary5;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 32px;

  @media (max-width: $desktop) {
    padding: 24px;
    min-height: 297px;
  }

  @media (max-width: $tablet) {
    padding: 32px;
    min-height: 355px;
  }


  .stars-block {
    display: flex;
    align-items: center;
    gap: 4px;

    .stars-block-svg {
      width: 26px;
      height: 26px;
    }
  }

  .customer-card-text {
    color: $titleColor3;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .customer-info {
    display: flex;
    align-items: center;
    gap: 16px;

    .customer-info-img {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 56px;
      min-width: 56px;
      max-height: 56px;
      min-height: 56px;
      background: #f9fbff;
      border-radius: 50%;
      color: #0c66e4;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name-position {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .customer-name {
        color: $titleColor3;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .customer-position {
        color: $titleColor5;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
