
.integration-title {
  color: #172B4D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.integration-type-title {
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.office-ally-header {
  padding-bottom: 12px;
  border-bottom: 1px solid #E4E7EC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;

  p {
    color: #172B4D;
    font-size: 24px;
    font-weight: 700;
  }

  .office-ally-header-icons {
    display: flex;
    align-items: center;
    gap: 12px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid var(--Gray-200, #E4E7EC);
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
}

.office-ally-box{
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0px 0
}

.edit-office-ally-connection{
  padding: 16px;
  border-top: 1px solid #E4E7EC;
  background: #FFF;
}