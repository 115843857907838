@import "src/assets/styles/globalStyles";

.add-role-modal {
  width: 420px;

  .add-role-buttons {
    margin-top: 14px;

    @media (max-width: $tablet) {
    }
  }
}