@import "src/assets/styles/globalStyles";

@mixin input-style {
  width: 100%;
  border: none;
  outline: none;
  padding: 16px;
  background-color: $backgroundPrimary;
  border-radius: 10px;
  @include font-style(16px, 400, $textSecondary, 20px);
}


.contact-us-page {
  padding: 208px 0;
  font-family: Inter, sans-serif;
  background: $textWhite;

  @media (max-width: $desktop) {
    padding: 144px 0;
  }
  @media (max-width: $tablet) {
    padding: 96px 0 64px;
  }

  .contact-us-sub-title {
    color: $themePrimaryBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    @media (max-width: $tablet) {
      font-size: 14px;
    }
  }

  .contact-us-title {
    color: $titleColor;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    margin: 16px 0;

    @media (max-width: $desktop) {
      font-size: 32px;
    }

    @media (max-width: $tablet) {
      font-size: 24px;
      margin: 4px 0 16px;
    }
  }

  .contact-us-description {
    max-width: 689px;
    color: $titleColor3;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0 auto;

    @media (max-width: $desktop) {
      font-size: 16px;
    }
  }

  .tabs {
    width: 100%;
    display: flex;
    margin-top: 80px;

    @media (max-width: $desktop) {
      margin-top: 48px;
    }

    @media (max-width: $tablet) {
      margin-top: 40px;
    }

    .tab {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      cursor: pointer;
      padding: 40px 80px;


      @media (max-width: $desktop) {
        padding: 24px 32px;
      }

      @media (max-width: $tablet) {
        padding: 24px 16px;
      }

      &:nth-child(1) {
        border-radius: 4px 0 0 0;
      }

      &:nth-child(2) {
        border-radius: 0 4px 0 0;
      }


      &.active {
        background-color: $backgroundOrange;
        transition: all .4s;

        .first-text, .second-text {
          //color: $backgroundSecondary5;
          transition: all .3s;
        }

        .first-text {
          @media only screen and (max-width: $tablet) {
            font-weight: 700;
          }
        }
      }

      .first-text {
        width: 100%;
        color: $titleColor;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;

        @media only screen and (max-width: $desktop) {
          font-size: 24px;
          text-align: start;
        }

        @media only screen and (max-width: $tablet) {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
        }
      }

      .second-text {
        color: $titleColor3;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @media only screen and (max-width: $desktop) {
          font-size: 24px;
          text-align: start;
        }

        @media only screen and (max-width: $tablet) {
          display: none;
        }
      }
    }
  }

  .inputs-wrapper {
    border-radius: 4px 0 4px 4px;
    background: $backgroundSecondary7;
    padding: 40px 80px;

    @media (max-width: $desktop) {
      padding: 24px 32px;
    }

    @media (max-width: $tablet) {
      padding: 24px 16px;
    }

    .get-in-touch-wrapper {
      .inputs-form {
        display: flex;
        flex-direction: column;
        gap: 28px;

        @media (max-width: $tablet) {
          gap: 0;
        }

        .inputs-form-display-flex {
          display: flex;
          gap: 40px;

          @media (max-width: $desktop) {
            gap: 32px;
          }

          @media (max-width: $tablet) {
            flex-direction: column;
            gap: 0;
          }
        }

      }
    }
  }

  .inputs-wrapper-with-paddings {
    border-radius: 4px 0 4px 4px;
    background: $backgroundSecondary7;
    padding: 40px 80px;

    @media (max-width: $desktop) {
      padding: 40px 32px;
    }

    @media (max-width: $tablet) {
      padding: 40px 16px;
    }

    .get-in-touch-wrapper {
      .inputs-form {
        display: flex;
        flex-direction: column;
        gap: 28px;

        @media (max-width: $tablet) {
          gap: 0;
        }

        .inputs-form-display-flex {
          display: flex;
          gap: 40px;

          @media (max-width: $desktop) {
            gap: 32px;
          }

          @media (max-width: $tablet) {
            flex-direction: column;
            gap: 0;
          }
        }

        .checkbox-button-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 20px;

          @media (max-width: $tablet) {
            justify-content: center;
          }

          .send-button {
            width: 203px;
            height: 56px;

            @media (max-width: $tablet) {
              max-width: 311px;
              width: 100%;
              height: 48px;
            }
          }
        }
      }
    }
  }

  //SocialContactUs
  .social-contact-us-wrapper {
    .social-contact-us-box {
      display: flex;
      align-items: flex-start;
      //justify-content: space-between;
      padding: 64px 0;

      @media (max-width: $desktop) {
        padding: 40px 0;

      }
      @media (max-width: $tablet) {
        flex-direction: column;

      }

      .social-contact-us-info {
        width: calc(100% / 2);
        border-right: 1px solid rgba(28, 28, 28, 0.08);
        padding: 0 40px 0 80px;

        @media (max-width: $desktop) {
          padding: 0 32px 0 0;

        }

        @media (max-width: $tablet) {
          width: 100%;
          padding: 24px 0;
          border-right: none;
          border-bottom: 1px solid rgba(28, 28, 28, 0.08);
        }
      }

      .contact-us-social {
        width: calc(100% / 3);
        //border-right: 1px solid rgba(28, 28, 28, 0.08);
        padding: 0 40px 0 80px;

        @media (max-width: $desktop) {
          padding: 0 32px 0 32px;
        }

        @media (max-width: $tablet) {
          width: 100%;
          padding: 24px 0;
          border-right: none;
          border-bottom: 1px solid rgba(28, 28, 28, 0.08);
        }

      }

      .social-contact-us-experience {
        width: calc(100% / 3);
        padding: 0 80px 0 80px;

        @media (max-width: $desktop) {
          padding: 0 32px 0 32px;
        }

        @media (max-width: $tablet) {
          width: 100%;
          padding: 24px 0;
        }
      }

      .social-contact-us-title {
        color: $backgroundButton;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-transform: capitalize;
        margin-bottom: 12px;
      }

      .social-contact-us-items-block {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .social-contact-us-items {
          display: flex;
          align-items: center;
          gap: 16px;

          .social-contact-us-svg {
            min-width: 24px;
            min-height: 24px;
          }

          &.gap-8 {
            gap: 8px;
          }

          .social-contact-us-link {
            color: $titleColor5;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
          }
        }
      }

      .title-bold {
        color: $backgroundButton;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }

      .sub-title {
        color: $titleColor5;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-top: 4px;
      }
    }
  }

  //FAQ

  .faq-social-wrapper {
    width: 100%;
    padding-top: 100px;

    @media (max-width: $desktop) {
      padding-top: 64px;
    }


    .contact-accordion-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 56px;

      .MuiAccordion-root:before {
        display: none;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiAccordion-root.Mui-expanded {
        background: $backgroundSecondary7;
      }

      .contact-accordion-block {
        border: 1px solid rgba(52, 122, 240, 0.10);

        .contact-accordion-summary {
          width: 100%;
          display: flex;
          align-items: flex-start;
          padding: 24px 32px;
          border-radius: 8px;
          background: transparent;
          min-height: auto;

          @media (max-width: $tablet) {
            padding: 24px 16px;
          }

          &.Mui-expanded {
            padding: 24px 32px 16px;

            @media (max-width: $tablet) {
              padding: 24px 16px 16px;
            }
          }

          .MuiIconButton-root {
            padding: 0;
            margin: 0;
          }

          .Mui-expanded {
            svg {
              path {
                stroke: $themePrimaryBold;
              }
            }
          }

          .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
            transform: rotate(180deg);

          }

          &::before {
            height: 0;
          }
        }


        .MuiAccordionDetails-root {
          padding: 0;
        }

        .MuiAccordionSummary-content {
          color: $titleColor6;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          margin: 0;


          @media (max-width: $tablet) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .contact-accordion-description {
          color: $titleColor7;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          padding: 0 32px 24px;

          @media (max-width: $tablet) {
            font-size: 16px;
            line-height: 24px;
            padding: 0 16px 24px;
          }
        }
      }
    }
  }

}
