@import "../../../assets/styles/globalStyles.scss";

.staff-information-wrapper {
  border-radius: 8px;
  background: #FFF;
  //box-shadow: 0px 0px 6px 0px rgba(52, 122, 240, 0.20);
  width: 524px;
  height: auto;
  min-height: 364px;
  //padding: 24px;
  .title {
    color: #4B5C68;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .information-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #4B5C68;
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0 24px;
  }

  .total-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }

  .total-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 11px;

    .title {
      width: 95px;
      white-space: nowrap;
      color: #4B5C68;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 16px;
    }

    .description {
      color: #4B5C68;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.processed-table-wrapper {
  margin-top: 24px;
}

.table-head-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background: #EBF2FD;
  height: 50px;


  .table-head-row {
    padding: 16px;
    color: #4B5C68;
    font-size: 14px;
    font-weight: 600;
  }
}

.table-body-wrapper {
  width: 100%;
  height: 65vh;
  overflow: auto;

  .MuiAccordion-rounded {
    border-radius: 8px !important;
  }

  .MuiAccordion-root:before {
    height: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 4px 0 0;
  }

  .MuiAccordion-rounded:first-child {

  }

  .MuiAccordion-root.Mui-expanded:first-child {
    margin: 4px 0 0;
  }

  .MuiPaper-elevation1 {
    box-shadow: 0px 0px 6px 0px rgba(138, 138, 138, 0.24);
  }

  .accordion-wrapper {
    margin-top: 4px;

    //:hover{
    //  background: rgba(235, 242, 253, 0.50);
    //  border-radius: 8px;
    //}


    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px;
    }

    .MuiAccordionSummary-root {
      padding: 0;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    background: white;
    border-radius: 0 0 8px 8px;
    padding: 16px 8px;
  }

  .table-body-row {
    display: flex;
    align-items: center;
    padding: 16px;
    color: #4B5C68;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.accordion-details-wrapper {
  width: 100%;

  .accordion-details-head-wrapper {
    display: flex;
    align-items: center;
    background: white;
    min-height: 36px;
  }

  .accordion-details-head-row {
    color: #A3B2BD;
    font-size: 12px;
    font-weight: 700;
    padding: 0 8px;
  }


  .accordion-details-body-wrapper:hover{
    background: rgba(235, 242, 253, 0.50);
    border-radius: 8px 8px 0 0;
  }

  .accordion-details-body-wrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8E5FF;
    background: #FFF;
    min-height: 36px;

    .accordion-details-body-row {
      cursor: pointer;
      color: #4B5C68;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 8px;

      //:hover{
      //  background: rgba(235, 242, 253, 0.50);
      //  border-radius: 8px;
      //}
    }
  }
}

.not-yet {
  color: #4B5C68;
  font-size: 14px;
  font-weight: 400;
}


.payroll-filters-wrapper {
  @include spaceBetween();
  align-items: flex-end;



  .MuiAutocomplete-root {
    //width: 145px !important;
    //@media (min-width: 1919px) {
    width: 220px !important;
    //}
  }

}

.process-modal-title {
  color: #172B4D;
  font-size: 24px;
  font-weight: 700;
}

.process-modal-desc {
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 12px 0 24px;
}

.matching-box {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;

  p {
    color: #172B4D;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }
  div{
    height: 22px;
    padding: 2px 8px;
    border-radius: 16px;
    background: #EAF1FD;
    color: #0C66E4;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.timesheet-description{
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background: #F2F4F8;
  padding: 16px;
  margin-top: 24px;
  color: #172B4D;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  gap: 8px;
  width: fit-content;
  min-width: 500px;

  p{
    font-weight: 600;
  }
  span{
    font-weight: 400;
  }
}