
.schedule-fragment-page {

  .lock-unlock-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    background: #FFF;
    padding: 8px 16px;
    @media (min-width: 1919px) {
      padding: 8px 32px;
    }

    .flex-lock {
      display: flex;
      align-items: center;
    }

    .lock-btn {
      border: 1px solid #FE7070;
      color: #FE7070;
    }

    .unlock-btn {
      border: 1px solid #2A8E6D;
      color: #2A8E6D;
    }

    button:first-child {
      margin-right: 12px;
    }

    button {
      background: transparent;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px;
      height: 36px;
      padding: 0 8px;
      @media (min-width: 1919px) {
        padding: 0 16px;
      }

      img {
        margin-right: 4px;
      }
    }
  }

  .appt-selected-info {
    @include alignCenter;
    gap: 8px;
    @media (min-width: 1919px) {
      gap: 16px;
    }

    p {
      color: #4B5C68;
      font-size: 14px;
      font-weight: 400;
      margin-top: 2px;
    }

    b {
      font-weight: 600;
    }

    button {
      color: #347AF0;
      font-size: 14px;
      font-weight: 600;
      border: none;
      height: 30px;
    }

    button:hover {
      border-radius: 4px;
      background: rgba(239, 248, 255, 0.50);
    }
  }

  .status-circle {
    margin-right: 8px;
    min-width: 12px;
    width: 12px;
    height: 12px;
    border-radius: 40px;
  }

  .link {
    p {
      text-decoration: underline;
    }
  }


  .accordion-body-item-center {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: rgba(34, 34, 34, 0.80);
    font-size: 14px;
    font-weight: 400;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      margin-right: 8px;
    }
  }

  .schedule-time {
    color: #4B5C68;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
  }

  .accordion-schedule-body {
    background: transparent;
    height: 65vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    .MuiAccordion-root.Mui-expanded {
      margin: 0 0 8px 0;
    }

    .MuiAccordion-root {
      box-shadow: 0px 2px 6px #347af033;
      border-radius: 8px;
      border-bottom: 1px solid #FAFAFA;
      margin-bottom: 8px;
    }


    .MuiAccordion-root:before {
      background: transparent;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      background: rgba(235, 242, 253, 0.50);
      border-bottom: 1px solid #FAFAFA;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      min-height: 50px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionDetails-root {
      padding: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .schedule-details-body {
    width: 100%;

    .schedule-details-table {
      margin-top: 16px;
    }

    .schedule-details-table-body {
      border-radius: 8px;
      background: #FAFAFA;
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 4px;
    }

    .schedule-details-table-body-item {
      padding: 10px 16px;
      color: rgba(34, 34, 34, 0.80);
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.schedule-details-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #172B4D;
  line-height: 24px;
  margin-bottom: 16px;
}

.signature-detail-box {
  max-width: 33%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(242, 244, 248, 0.50);
  padding: 9px 12px;

  p {
    line-height: normal;
  }
}

.signature-title {
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  width: 67px;
}

.signature-border-line {
  height: 1px;
  width: 100%;
  background: #E4E7EC;
  margin: 16px 0;
}

.signature-detail-box-title {
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.signature-detail-name {
  width: 150px;
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.signature-detail-box-value {
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
}

.full-width {
  width: 100%;
}


.detail-information {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.signature-process {
  border-radius: 8px;
  border: 2px solid #F2F4F8;
  background: #FFF;
  padding: 16px;
  margin-top: 16px;
}

.show-signature {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0C66E4;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-decoration-line: underline;
}

.download-signature {
  text-decoration-line: underline;
  color: #0C66E4;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.signature-date-time-wrapper {
  display: flex;
  align-items: flex-start;

  .location {
    margin-left: 8px;
    width: 100%;
  }

  .detail-value {
    margin-top: 4px;
  }
}

.buttons-wrapper {
  margin-top: 24px;
}

.signature-require-and-download-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.upload-download-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.staff-input-box {

  .staff-list-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #347AF0;
    background: #FFF;
    padding: 2px 16px 0;
    position: relative;

    &:hover {
      border: 1px solid black;
    }


    input {
      width: 80%;
      height: 40px;
      border: none;
      outline: none;
      position: absolute;
      font-size: 16px;
      background: transparent;
      color: #51566D;
    }

    input:disabled {
      color: rgba(75, 92, 104, 0.50);
    }

    input::placeholder {
      color: rgba(75, 92, 104, 0.50);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
    }

    p {
      white-space: nowrap;
      position: relative;
      z-index: 2;
    }

    .staff-input-not-selected {
      white-space: nowrap;
      color: rgba(75, 92, 104, 0.50);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
    }

    .staff-input-selected {
      //width: 68px;
      background: white;
      margin-top: -47px;
      margin-left: 0;
      font-size: 12px;
      color: #347AF0;
      padding: 0 4px;
    }

    .select-remove-icon {
      display: flex;
      align-items: center;
    }

    .remove-selected-staff {
      display: none;
      background: transparent;
      border: none;
      margin-top: 3px;

      img {
        filter: brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(7468%) hue-rotate(247deg) brightness(79%) contrast(77%);
      }
    }

    .input-rotate-icon {
      width: 14px;

      filter: brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(7468%) hue-rotate(247deg) brightness(79%) contrast(77%);

    }
  }

  .staff-list-input:hover {
    .remove-selected-staff {
      display: block;
    }
  }

}

.beck-drop {
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
}

.custom-select-paper-box {
  box-shadow: 0px 0px 6px #0000001a;
  margin-top: 4px;
  width: 400px;
  max-height: 400px;
  overflow: auto;
  background: white;
  border-radius: 8px;
  padding: 8px 0;
}

.select-item-box {
  font-family: "Open Sans", sans-serif;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  color: #4B5C68;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  .recomended {
    color: #6FD231;
    font-size: 12px;
    font-weight: 600;
  }

  .not-recomended {
    color: #FFA330;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.select-item-box:hover {
  background: #f7f8fa;
}


.calendar-filters-view {
  .MuiFormControl-fullWidth {
    background: #F8FAFB 0% 0% no-repeat padding-box;
  }
}

.drag-and-drop-calendar {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-bottom: 24px;
  //box-shadow: 0px 0px 6px #347af04d;
  //border-radius: 8px;

  .schedule-filters {
    padding: 24px;
  }

  .rbc-date-cell {
    padding-bottom: 2px;
  }

  .rbc-show-more {
    //position: absolute;
    //top: 0;
    color: rgb(52, 122, 240);
  }

  .rbc-row-content {
    //overflow: auto;
  }

  .rbc-addons-dnd-resizable {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .rbc-event-label {
    display: none;
  }

  //.rbc-time-slot{
  //  background: transparent!important;
  //}

  //.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  //  width: 100%!important;
  //  left: 10px!important;
  //}

  .rbc-event {
    padding: 4px;
    width: 100%;
  }

  .calendar-card-title {
    color: #4B5C68;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 4px;
  }

  .calendar-card-time-box {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #4B5C68;
    font-size: 12px;
    font-weight: 400;
  }

  .calendar-card-cpt {
    margin-bottom: 4px;
    color: #4B5C68;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .calendar-card-staff {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #3F3F3F;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    height: 100%;
  }

  .staff-icon-box {
    width: 18px;
    height: 18px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.schedule-links-box {
  margin: 12px 0 24px 0;
}

.add-link-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #172B4D;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  background: transparent;
  border: none;
}

.schedule-link-inputs-box {
  border-radius: 4px;
  background: rgba(235, 242, 253, 0.50);
  padding: 16px;
  margin-top: 16px;
  border: 1px solid rgba(235, 242, 253, 0.50);
}

.schedule-link-inputs {
  gap: 16px;
  //display: flex;
  //align-items: center;
  //margin-bottom: 16px;
  div {
    width: 100%;
    text-align: start;
  }

  //input {
  //  display: flex;
  //  width: 100%;
  //  padding: 13px 16px;
  //  border-radius: 4px;
  //  border: 1px solid;
  //  background: #FFF;
  //  color: #4B5C68;
  //  font-size: 16px;
  //  font-weight: 400;
  //  line-height: 23px;
  //  outline: none;
  //}
  //
  //p {
  //  color: #4B5C68;
  //  font-size: 14px;
  //  font-weight: 600;
  //  line-height: 21px;
  //  margin-bottom: 4px;
  //}

  //button {
  //  border: none;
  //  background: transparent;
  //  padding: 0;
  //}
}

.cancel-link-btn {
  @include flexEnd;
  margin-top: 8px;

  button {
    @include resetBtn;
    color: #4B5C68;
    font-size: 14px;
    font-weight: 400;
  }
}

.signature-process-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.schedule-link-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4B5C68;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
  margin: 16px 0;
  background: transparent;
  border: none;
}

.schedule-link-wrapper:last-of-type {
  margin: 0;
}

.link-details-btn {
  gap: 8px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #4B5C68;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;

  img {
    width: 16px;
    height: 16px;
  }
}

.filter-passive-btn {
  border-radius: 4px;
  border: 1px solid rgba(163, 178, 189, 0.50);
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 36px;
  width: 110px;
  color: #172B4D;
  font-size: 16px;
  font-weight: 600;
}

.active-filter {
  background: #347AF0;
  color: white;

  img {
    filter: contrast(0%) brightness(2);
  }
}

.schedule-filter-wrapper {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.schedule-filter-box {
  display: flex;
  align-items: center;
  gap: 16px;
}

.full-view {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(163, 178, 189, 0.50);
  background: #FFF;
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.interval-btn {
  position: absolute;
  background: #F8FAFB;
  border: none;
  width: 85px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #152E47;
  font-size: 12px;
  font-weight: 400;
  border-right: 1px solid #ebf2fd;
  border-top: 1px solid #ebf2fd;
}

.interval-selector-box {
  padding: 4px 0;
  width: 140px;
  border: 1px solid #F4F4F4;
  border-radius: 4px;
  background: white;
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
}

.interval-buttons {
  text-align: start;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 16px;
  color: #4B5C68;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  height: 36px;
}

.interval-buttons:hover {
  background: #EBF2FD;
}

.active-interval {
  background: #EBF2FD;
}

.calendar-view-box {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 6px 0px rgba(52, 122, 240, 0.20);
}

.calendar-filter-box {
  padding: 24px 16px 0;
}


.more-appts-modal {
  width: 720px;

  .more-appt-title {
    color: #172B4D;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}


.appt-detail-title {
  color: #172B4D;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.appt-detail-status-box {
  margin: 10px 0;
}

.appt-status-card {
  height: 25px;
  border-radius: 16px;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  width: fit-content;

  div {
    width: 6px;
    height: 6px;
    border-radius: 40px;
  }
}

.appt-not-rendered {
  background: #F2F4F7;
  color: #344054;

  div {
    background: #667085;
  }
}

.appt-rendered {
  background: #EFF8FF;
  color: #175CD3;

  div {
    background: #2E90FA;
  }
}

.appt-cancelled {
  background: #F2F4F7;
  color: #344054;

  div {
    background: #344054;
  }
}

.service-appt-pending {
  background: rgb(241, 250, 234);
  color: #172B4D;

  div {
    background: rgb(111, 210, 49);
  }
}

.appt-pending {
  background: rgb(235, 242, 253);
  color: #172B4D;

  div {
    background: rgba(52, 122, 240, 0.5);
  }
}

.appt-completed {
  background: rgb(235, 242, 253);
  color: #172B4D;

  div {
    background: rgb(52, 122, 240);
  }
}

.service-appt-completed {
  background: rgb(234, 244, 240);
  color: #172B4D;

  div {
    background: rgb(42, 142, 109);
  }
}

.appt-bill-status{
  color:#475467;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 8px;
  margin-top: 2px;
}

.service-appt-completed-bill {
  height: 25px;
  width: fit-content;
  padding: 2px 8px;
  color: #363F72;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #D0D5DD;
  background: #FFF;
}

.appt-service-bill-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (min-width: 1919px) {
    flex-direction: row;

  }
}

.appt-buttons-box {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    background: transparent;
    border: none;
    padding: 0;
  }
}

.appt-detail-box {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;

  .appt-detail-modal-title {
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .appt-detail-modal-value {
    color: #172B4D;
    font-size: 14px;
    font-weight: 400;
  }
}

.appt-detail-card-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;

  .detail-item-wrapper {
    width: 49.5%;
    margin-bottom: 0;
  }
}

.render-btn-box {
  border-radius: 4px;
  border: 1px solid #0C66E4;
  background: #0C66E4;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  padding: 10px 18px;
  white-space: nowrap;
}

.cancel-btn-box {
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  background: #F2F4F8;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  padding: 10px 18px;
  color: #172B4D;
}

.inature-drawwer-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  p {
    color: #172B4D;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}

.attached-links-title {
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
}

.attached-link-body {
  width: 50%;
  height: 100%;
  max-height: 215px;
  overflow: auto;
}

.attached-link-box {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.attached-link-name {
  width: 468px;
  height: 36px;
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(242, 244, 248, 0.50);
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.appt-detail-modal {
  width: 1100px;
  position: relative;
  //@media (min-width: 1919px) {
  //  width: 1600px;
  //}
}

.canceled-reason-wrapper {
  width: 300px;
}

.canceled-reason {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #172B4D;
  font-size: 12px;
  font-weight: 600;
}

.appt-actions-box {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (min-width: 1919px) {
    gap: 12px;
  }
}

.appt-action-btn {
  width: 36px;
}

.require-box {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  padding: 12px 0;
}

.date-time-text {
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: start;
}

.drag-drop-signature-component {
  padding: 12px;
  //height: 60px;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed #A9B7C1;
  background: #FCFCFD;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  input[type='file'] {
    display: none;
  }

  img {
    width: 32px;
    height: 32px;
  }

  .upload {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #172B4D;
    cursor: pointer;
  }

  .signature-upload-btn {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 12px;
    border-radius: 4px;
    border: 1px solid #E4E7EC;
    background: #FFF;
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  .upload-or {
    color: #98A2B3;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 145%;
    margin: 4px 0;
  }
}

.uploaded-signature-box {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  padding: 12px;
}

.uploaded-signature-image {
  width: 24px;
  min-width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 4px;
}

.uploaded-signature-name {
  color: #172B4D;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.uploaded-signature-information {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}

.signature-tabs-wrapper {
  max-height: 650px;
  height: 75vh;
  width: 800px;
}

.canvas-wrapper {
  border-radius: 4px;
  background: var(--Foundation-Background, #F2F4F8);
  height: 167px;
  width: 100%;
  padding: 12px 40px;


  .canvas-line {
    height: 1px;
    background: #98A2B3;
    width: 100%;
    margin-bottom: 12px;
  }

  .clear-canvas-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      background: transparent;
      border: none;
      outline: none;
      color: #51439E;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
    }
  }
}

.draw-info-text {
  color: #475467;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 20px 0 0;
}

.signature-type-title {
  color: #172B4D;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}

.signature-modal-info-box {
  padding: 20px 0 16px;
  border-bottom: 1px solid #E4E7EC;
  margin-bottom: 32px;
}

.reasons-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E4E7EC;
  padding-bottom: 32px;

  .reason-for {
    color: #172B4D;
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0;
  }

  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: #172B4D;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

  }
}


.apply-miles-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 4px;
  background: #EBF2FD;
  margin-bottom: 16px;

  button {
    padding: 0;
    background: transparent;
    border: none;
    color: #0C66E4;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    color: #172B4D;
    font-size: 14px;
    font-weight: 600;
  }

  span {
    color: #172B4D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.status-switch-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    color: #414651;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}