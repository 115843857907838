
.submit-claim-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  background: #FFF;
  min-height: 70px;
  padding: 10px 32px;
  margin: 32px 2px -27px;

  .submit-claim-text {
    color: #4B5C68;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .submit-claim-button {
    border-radius: 8px;
    border: 1.5px solid #347AF0;
    height: 36px;
    width: 206px;
    color: #347AF0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.batch-claims-head {
  display: flex;
  justify-content: space-between;
  color: #475467;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;

  p {
    padding: 10px 8px;
  }
}

.batch-claims-body {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: #4B5C68;
  font-size: 14px;
  background: rgba(235, 242, 253, 0.50);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;

  .batch-item {
    padding: 10px 8px;
  }


}

//.batch-claims-body:hover{
//  background: rgba(235, 242, 253, 0.50);
//}

.action-btn {
  button {
    color: #347AF0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-decoration-line: underline;

    img {
      margin-left: 8px;
    }
  }
}

.pending-claim-box {
  margin-top: 24px;
}

.download-1500form-modal-body {
  margin: 32px 0 16px;

  .form-type-box-passive {
    border-radius: 8px;
    border: 1px solid #E4E7EC;
    background: transparent;
    padding: 8px 16px;

    .MuiTypography-body1 {
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-family: Inter, sans-serif !important;
    }
  }

  .form-type-box-active {
    border-radius: 8px;
    border: 1px solid #D8E5FF;
    background: rgba(235, 242, 253, 0.50);
    padding: 8px 16px;

    .MuiTypography-body1 {
      color: #347AF0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-family: Inter, sans-serif !important;
    }
  }

  .download-manual-types-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #D8E5FF;

    .MuiTypography-body1 {
      color: #172B4D;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.office-ally-warning-text {
  color: #172B4D;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 32px;
}

.claim-dos-and-flag {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  color: #172B4D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.show-signature-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0C66E4;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.claim-dos-and-flag-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  img {
    width: 20px;
    height: 20px;
  }
}

.claim-filters-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  @media (min-width: 1920px) {
    flex-direction: row;
    align-items: flex-end;
    gap: 0;
  }

  .claim-filter-box {
    @media (max-width: 1919px) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}