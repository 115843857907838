
.notifications-wrapper {
  width: 420px;
  max-height: 70vh;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 50px;
  box-shadow: 0px 0px 6px #8A8A8A29;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;

  .notifications-close-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 12px 0 24px;

    p {
      font-family: "Inter", sans-serif;
      color: #101828;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 12px;
    }
  }

  .notifications-button-wrapper {
    margin: 24px 24px 16px 24px;
    border-radius: 4px;
    border: 1px solid #F2F4F7;
    background: #F9FAFB;
    padding: 4px;
    display: flex;
    gap: 8px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 36px;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      border: none;
    }

    .note-active-btn {
      color: #344054;
      background: #FFF;
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    }

    .note-passive-btn {
      color: #475467;
      background: transparent;
    }
  }

  .mark-as-read {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
    padding: 12px 0;

    button {
      height: 25px;
      color: #0C66E4;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      background: transparent;
      border: none;
      margin-right: 24px;
    }
  }

  .note-card-wrapper {
    height: 400px;
    overflow: auto;
    padding-bottom: 20px;
  }
}
.notification-card:hover{
  .delete-notification-box {
    display: flex;
  }
}
.notification-card {
  position: relative;
  z-index: 1;
  margin-bottom: 4px;
  padding: 16px;
  border-bottom: 1px solid #E4E7EC;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  cursor: pointer;

  .new-notification {
    position: absolute;
    right: 13px;
    border-radius: 40px;
    background: #12B76A;
    width: 10px;
    min-width: 10px;
    height: 10px;
  }

  .notification-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #E4E7EC;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .notification-title-box {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;

    .p {
      color: #172B4D;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    span {
      color: #475467;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
    }

    div {
      width: 2px;
      height: 2px;
      background: #98A2B3;
      border-radius: 50%;
    }
  }

  .notification-sub-title {
    color: #172B4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .notification-date {
    color: #172B4D;
    font-size: 12px;
    font-weight: 400;
  }

  .delete-notification-box {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    display: none;
    background: transparent;
    border: none;
  }
}