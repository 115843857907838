@import "../../../src/assets/styles/globalStyles";

.button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.book-demo-button {
    position: fixed;
    top: 40px;
    z-index: 9;
    transform: translateY(-50%);
    right: calc((100% - 1440px) / 2 + 80px);
    @media (max-width: 1440px) {
      right: 80px;
    }
    @media (max-width: $desktop) {
      right: 32px;
    }
    width: 156px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0 0 6px #347af069;
    background-color: $themePrimary;
    margin-left: 32px;
    @include font-style(18px, 500, $textWhite, 20px);
  }

  &.book-demo-button-mobile {
    width: 230px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #0000001a;
    background-color: $themePrimary;
    @include font-style(16px, 500, $textWhite, 18px);
    margin-top: 16px;
  }

  &.sign-in-button {
    padding: 0;
    background-color: inherit;
    @include font-style(16px, 700, $textWhite, 18px);

    &:hover {
      color: $themePrimary;
    }
  }

  &.sign-in-button-mobile {
    @include font-style(20px, 700, $textSecondary, 23px);
    background-color: inherit;
  }

  &.sign-up-button {
    position: absolute;
    right: 8px;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    background-color: inherit;
  }

  &.book-demo-with-shadow-button {
    position: relative;
    width: 156px;
    height: 48px;
    background-color: $themePrimary;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #0000001a;
    @media (max-width: $desktop) {
      width: 230px;
    }
    @media (max-width: $mobile) {
      width: 263px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      width: 168px;
      height: 60px;
      background-color: #f8fafb1a;
      border-radius: 10px;
      @media (max-width: $desktop) {
        width: 242px;
      }
      @media (max-width: $mobile) {
        width: 275px;
      }
    }

    @include font-style(16px, 500, $textWhite, 24px);
  }

  &.watch-video-button {
    background-color: inherit;
    @include font-style(16px, 500, $textWhite, 24px);
    margin-left: 24px;
    @media (max-width: $desktop) {
      margin-left: 0;
      margin-top: 24px;
    }

    &::before {
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../../assets/images/landingPage/home/playVideo.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 8px;
    }
  }

  &.book-demo {
    margin: 0 auto;
    width: 153px;
    height: 48px;
    box-shadow: 0px 0px 6px #347af069;
    border-radius: 10px;
    background-color: $themePrimary;
    @include font-style(18px, 500, $textWhite, 20px);
  }

  &.read-more-button {
    display: inline-flex;
    background-color: inherit;
    padding: 0;
    @include font-style(16px, 500, $themePrimary, 24px);
  }

  &.back-to-homepage-button {
    margin: 0 auto;
    padding: 0;
    background-color: inherit;
    @include font-style(24px, 700, $themePrimary, 28px);
    @media (max-width: $desktop) {
      font-weight: 500;
    }
    @media (max-width: $mobile) {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &.send-message-button {
    width: 150px;
    height: 48px;
    background-color: #0390f0;
    box-shadow: 0px 0px 6px #347af069;
    border-radius: 10px;
    margin: 24px 0 0 auto;
    @include font-style(18px, 500, $textWhite, 20px);
  }

  &.submit-button {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    background-color: $themePrimary;
    @include font-style(16px, 500, $textWhite, 24px);
  }

  &.contact-support-button {
    position: relative;
    width: 200px;
    height: 48px;
    background-color: $themePrimary;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #347AF069;
    @media (max-width: $desktop) {
      width: 230px;
    }
    @media (max-width: $mobile) {
      width: 263px;
    }
    @media (max-width: $desktop) {
      margin: 0 auto;
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        width: 242px;
        height: 60px;
        background-color: #f8fafb1a;
        border-radius: 10px;
        @media (max-width: $mobile) {
          width: 275px;
        }
      }
    }
    @include font-style(16px, 500, $textWhite, 24px);
  }
}

.button-landing {
  @include flexCenter;
  font-family: Inter, sans-serif;
  border: none;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s;

  .flex-all-center {
    @include flexCenter;
    gap: 8px;
  }


  .button-text {
    color: $textWhite;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &.big {
    padding: 18px 32px;
    font-size: 18px;
    height: 56px;

    .button-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 100%
    }

    .flex-all-center {
      gap: 10px;
    }
  }

  &.small {
    padding: 16px 32px;
    height: 48px;
  }


  &.contained, .outlined {
  }

  &.contained {
    color: $textWhite;
    background: $themePrimaryBold;

    .arrow-right {
      min-width: 17px;
      min-height: 17px;
      transform: rotate(-45deg);
      transition: all .2s;

      path {
        stroke: $textWhite;
      }
    }

    &:hover {
      background: $backgroundButton;
      transition: all .2s;

      .arrow-right {
        transform: rotate(0deg);
        transition: all .2s;

        path {
          stroke: $textWhite;
        }
      }
    }
  }

  &.outlined {
    background: transparent;
    color: $backgroundButton;
    font-weight: 600;
    padding: 0 0 8px;
    border-bottom: 1px solid $backgroundButton;
    border-radius: 0;


    .arrow-right {
      min-width: 17px;
      min-height: 17px;
      transform: rotate(-45deg);
      transition: all .2s;

      path {
        stroke: $backgroundButton;
      }
    }

    .button-text {
      color: $backgroundButton;
    }

    &:hover {
      border-bottom: 1px solid $themePrimaryBold;

      .arrow-right {
        min-width: 17px;
        min-height: 17px;
        transform: rotate(0deg);
        transition: all .2s;


        path {
          stroke: $themePrimaryBold;
        }
      }

      .button-text {
        color: $themePrimaryBold;
      }
    }

    &.moovable {
      padding: 0 32px 8px 0;
      border-bottom: 1px solid $themePrimaryTiny;

      &:hover {
        border-bottom: 1px solid $backgroundButton;
        padding: 0 0 8px 32px;


        .button-text {
          color: $backgroundButton;
        }

        svg {
          path {
            stroke: $backgroundButton;
          }
        }
      }

      .button-text {
        color: $themePrimaryTiny;
      }

      svg {
        path {
          stroke: $themePrimaryTiny;
        }
      }
    }
  }


  &:disabled {
    cursor: auto;
    pointer-events: none;

    &.contained {
      color: $textWhite;
      opacity: .5;
    }

    &.outlined {

      .add-icon {
        opacity: .5;
      }

      .button-text {
        opacity: .5;
      }
    }
  }
}

.google-app-wrapper {
  display: flex;
  height: 48px;
  align-items: center;
  border: none;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s;
  background: #000;
  padding: 10px 24px;
  gap: 12px;

  &:hover {
    opacity: .8;
  }

  .google-app-svgs {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.inactivate-btb {
  border: none;
  height: 32px;
  padding: 0 16px;
  border-radius: 4px;
  background: $themeRed;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  & :hover {
    box-shadow: 0px 0px 6px 0px rgba(254, 112, 112, 0.30);
  }
}


.back-to-sign-in {
  @include resetBtn;
  color: #2B2B2B;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  white-space: nowrap;
}

.edit-btn-box {
  @include flexCenter;
  width: 36px;
  height: 36px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.square-btn-box {
  @include flexCenter;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.delete-btn-with-icon-box {
  @include flexCenter;
  width: 36px;
  height: 36px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.close-action-btn {
  height: 36px;
  padding: 8px 24px;
  border-radius: 4px;
  background: #FE7070;
  color: #FFF;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  border: none;
}

.new-action-btn-box {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.new-action-btn-box-small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.buttons-tab-wrapper {
  height: 36px;
  width: fit-content;
  display: flex;
  background: white;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0px 0px 6px 0px rgba(138, 138, 138, 0.24);

  & button {
    width: fit-content;
    background: transparent;
    border: none;
    font-size: 14px;
    color: #4B5C68;
    border-radius: 4px;
    height: 32px;
    padding: 0 16px;
    //padding: 0 32px;
    font-style: normal;
    line-height: normal;
  }

  & .active-btn {
    background: #347AF0;
    color: #FFFFFF;
    font-weight: 600;
    white-space: nowrap;
  }

  & .passive-btn {
    background: transparent;
    color: #4B5C68;
    font-weight: 400;
    white-space: nowrap;
  }
}

.checkbox-component-btn{
  white-space:nowrap;
  display:flex;
  justify-content:center;
  align-items:center;
  height: 36px;
  width: 161px;
  min-width:161px;
  border-radius: 4px;
  background: #347AF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 0;
}