@import "src/assets/styles/globalStyles";

.features-page {
  width: 100%;
  background: white;
  font-family: Inte, sans-serif;

  //FeatureBanner
  .feature-banner-wrapper {
    width: 100%;
    padding: 160px 0;

    @media (max-width: $desktop) {
      padding: 144px 0;
    }

    @media (max-width: $tablet) {
      padding: 108px 0;
    }

    .feature-banner-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .feature-banner_title {
        max-width: 692px;
        color: $titleColor;
        text-align: center;
        font-size: 42px;
        font-weight: 700;
        line-height: 60px;

        @media (max-width: $desktop) {
          font-size: 34px;
        }

        @media (max-width: $tablet) {
          font-size: 22px;
          line-height: 30px;
        }
      }

      .feature-banner_svg {
        margin: 24px 0;

        @media (max-width: $desktop) {
          margin: 16px 0;
        }

        path {
          fill: $themePrimaryBold;
        }
      }

      .feature-banner_description {
        color: $titleColor3;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 40px;

        @media (max-width: $desktop) {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }

      .feature-banner-buttons-block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
        gap: 32px;

        @media (max-width: $tablet) {
          flex-direction: column;
          align-items: center;
          gap: 16px;
        }

        .learn-more-button {
          &:hover {
            .play-svg {
              path {
                stroke: $themePrimaryBold;
              }
            }
          }
        }

        .request-demo-btn, .learn-more-button {
          @media (max-width: $tablet) {
            width: 100%;
            max-width: 343px;
          }
        }

        .request-demo-btn, .learn-more-button {
          @media (max-width: $tablet) {
            width: 100%;
            max-width: 343px;
          }
        }
      }
    }
  }

  //Solutions
  .solutions-wrapper {
    width: 100%;
    padding: 80px 0;
    background: white;

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .solutions-box {
      .solutions-blue-text {
        text-align: center;
      }

      .solutions-title {
        max-width: 710px;
        color: $titleColor;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        margin: 12px auto 24px;

        @media (max-width: $desktop) {
          font-size: 28px;
          margin: 8px auto 24px;
        }

        @media (max-width: $tablet) {
          font-size: 22px;
          margin: 8px auto 16px;
        }
      }

      .solutions-desc {
        max-width: 717px;
        color: $titleColor3;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        margin: 0 auto;
      }

      .tabs-wrapper {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 4px;
        border: 0.5px solid rgba(130, 138, 165, 0.40);
        background: $backgroundSecondary5;
        margin: 40px auto 40px;

        @media (max-width: $desktop) {
          gap: 16px;
        }

        @media (max-width: $tablet) {
          flex-wrap: wrap;
          gap: 16px;
          border: none;
          margin: 32px auto 32px;
        }

        .single-tab-wrapper {
          padding: 12px 24px 12px 0;
          border-radius: 4px;
          cursor: pointer;

          @media (max-width: $tablet) {
            padding: 12px 16px;
            border: 0.5px solid rgba(130, 138, 165, 0.40);
          }


          &:hover {
            background: $backgroundButton;

            .single-tab_text {
              color: $backgroundSecondary5;
              border-left: 1px solid $backgroundButton;

              @media (max-width: $tablet) {
                border: none;
              }

            }
          }

          &.active-tab {
            background: $backgroundButton;

            .single-tab_text {
              color: $backgroundSecondary5;
              border-left: 1px solid $backgroundButton;

              @media (max-width: $tablet) {
                border: none;
              }
            }
          }

          .single-tab_text {
            color: rgba(41, 42, 46, 0.80);
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            border-left: 1px solid rgba(130, 138, 165, 0.40);
            padding-left: 24px;

            @media (max-width: $desktop) {
              font-size: 16px;
            }

            @media (max-width: $tablet) {
              border-left: none;
              padding-left: 0;
            }

            &.border-none {
              border-left: none;
            }
          }
        }
      }


      .solutions-card-wrapper {
        display: flex;
        gap: 24px;
        margin-top: 40px;

        @media (max-width: $desktop) {
          flex-direction: column;
          margin-top: 40px;
        }

        @media (max-width: $tablet) {
          flex-direction: column;
          margin-top: 32px;
        }

        .solutions-card-item {
          width: calc(100% / 3 - 16px);

          @media (max-width: $desktop) {
            width: calc(100% - 40px);
          }

          @media (max-width: $tablet) {
            width: calc(100% - 16px);
          }
        }
      }
    }

  }

  //ElectronicRecords
  .electronic-records-wrapper {
    font-family: Inter, sans-serif;
    background: white;
    padding: 80px 0;

    @media (max-width: $desktop) {
      padding: 64px 0 0;
    }

    .electronic-records-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 105px;

      @media (max-width: $desktop) {
        flex-direction: column;
        gap: 24px;
      }

      .electronic-records-content {
        width: 100%;
        max-width: 551px;

        @media (max-width: $desktop) {
          max-width: 100%;
        }

        .electronic-records-content_box {
          max-width: 480px;

          @media (max-width: $desktop) {
            max-width: 100%;
          }

          .electronic-records-title {
            color: $titleColor;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: -0.9px;
            margin-top: 12px;

            @media (max-width: $desktop) {
              font-size: 28px;
              margin-top: 8px;
            }
          }

          .electronic-records-desc {
            color: $titleColor3;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin: 16px 0 32px;

            @media (max-width: $desktop) {
              font-size: 16px;
              margin: 16px 0 24px;
            }
          }

          .electronic-mini-card-wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;


            .electronic-mini-card_item {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 12px 16px;
              gap: 12px;
              background: $backgroundSecondary11;
              border-radius: 4px;
            }
          }
        }
      }

      .electronic-records-img {
        max-width: 592px;
        aspect-ratio: 592/485;

        @media (max-width: $desktop) {
          max-width: 100%;
          max-height: 367px;
          aspect-ratio: 688/367;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }


  //BillingPosting
  .billing-posting-wrapper {
    width: 100%;
    padding: 80px 0;

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    .billing-posting-box {
      max-width: 1207px;
      margin: 0 auto;

      .billing-posting-title {
        color: $titleColor;
        font-family: Inter, sans-serif;
        font-size: 34px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: -0.9px;
        margin-top: 8px;

        @media (max-width: $desktop) {
          font-size: 28px;
        }
      }

      .billing-posting-desc {
        max-width: 815px;
        color: $titleColor3;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        margin: 14px 0 64px;

        @media (max-width: $desktop) {
          font-size: 16px;
          margin: 16px 0 24px;
        }
      }

      .billing-mini-card-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px 17px;

        @media (max-width: $desktop) {
          gap: 16px 24px;
        }

        .billing-system-card-wrapper {
          max-width: 376px;
          width: 33%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .billing-system-img {
            width: 100%;
            aspect-ratio: 376/251;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }

            @media (max-width: $tablet) {
              width: 100%;
              max-width: 100%;
            }
          }
        }

        .powered-card-wrapper {
          max-width: 336px;
          width: 33%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .powered-img {
            width: 100%;
            aspect-ratio: 336/244;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }

            @media (max-width: $tablet) {
              width: 100%;
              max-width: 100%;
            }
          }
        }

        .automated-card-wrapper {
          max-width: 460px;
          width: 40%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .automated-img {
            width: 100%;
            aspect-ratio: 460/253;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }

            @media (max-width: $tablet) {
              width: 100%;
              max-width: 100%;
            }
          }
        }

        .direct-card-wrapper {
          max-width: 350px;
          width: 35%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }
          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .direct-img {
            width: 100%;
            aspect-ratio: 350/257;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }

          }
        }

        .consulting-card-wrapper {
          max-width: 350px;
          width: 35%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .consulting-img {
            width: 100%;
            aspect-ratio: 350/244;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }
          }
        }

        .posting-card-wrapper {
          max-width: 468px;
          width: 50%;

          @media (max-width: $desktop) {
            width: 100%;
            max-width: calc(100% / 2 - 16px);
          }

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
          }

          .posting-img {
            width: 100%;
            aspect-ratio: 469/244;

            @media (max-width: $desktop) {
              aspect-ratio: 336/244;
            }
          }

        }
      }
    }
  }

  //Scheduling
  .scheduling-wrapper {
    width: 100%;
    padding: 80px 0;

    @media (max-width: $desktop) {
      padding: 64px 0 0;
    }

    .scheduling-box {
      gap: 105px;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        gap: 24px;
      }


      .scheduling-img {
        max-width: 592px;
        aspect-ratio: 592/444;

        @media (max-width: $desktop) {
          max-width: 688px;
          aspect-ratio: 688/316;
          margin: 0 auto;
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }
    }
  }

  //payroll-wrapper
  .payroll-wrapper {
    padding: 80px 0;
    background: linear-gradient(182deg, #F9FCFF 2.21%, #FBFBFB 29.01%, #FFF 98.2%);

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .payroll-box {
      flex-direction: row-reverse;
      align-items: flex-end;
      gap: 64px;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 24px;
      }

      .payroll-img {
        max-width: 531px;
        max-height: 393px;
        aspect-ratio: 531/393;

        @media (max-width: $desktop) {
          max-width: 407px;
          max-height: 381px;
          aspect-ratio: 407/381;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }

      .payroll-content-wrapper {
        width: 685px;

        @media (max-width: $desktop) {
          width: 100%;
        }

        .about-something_description {
          max-width: 559px;

          @media (max-width: $desktop) {
            max-width: 100%;
          }
        }

        .payroll-content {
          max-width: 605px;

          @media (max-width: $desktop) {
            max-width: 100%;
          }
        }
      }
    }
  }

  //credential-wrapper
  .credential-wrapper {
    width: 100%;
    padding: 80px 0;
    background: linear-gradient(182deg, #F9FCFF 2.21%, #FBFBFB 29.01%, #FFF 98.2%);

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .credential-box {
      gap: 104px;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        gap: 24px;
      }

      .credential-img {
        max-width: 592px;
        aspect-ratio: 592/444;

        @media (max-width: $desktop) {
          max-width: 561px;
          aspect-ratio: 561/394;
          margin-left: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }
    }
  }

  //Powerful

  .powerful-wrapper {
    padding: 80px 0;
    background: linear-gradient(182deg, #F9FCFF 2.21%, #FBFBFB 29.01%, #FFF 98.2%);

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .powerful-box {
      flex-direction: row-reverse;
      align-items: flex-end;
      gap: 32px;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        gap: 24px;
      }

      .powerful-img {
        max-width: 711px;
        max-height: 185px;
        aspect-ratio: 711/185;

        @media (max-width: $desktop) {
          max-width: 688px;
          max-height: 149px;
          aspect-ratio: 688/149;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }

      .powerful-content-wrapper {
        width: 592px;

        @media (max-width: $desktop) {
          width: 100%;
        }

        .powerful-content {
          max-width: 474px;

          @media (max-width: $desktop) {
            max-width: 100%;
          }
        }
      }
    }
  }

  //access-key-wrapper

  .access-key-wrapper {
    width: 100%;
    padding: 80px 0;
    background: $backgroundSecondary11;

    @media (max-width: $desktop) {
      padding: 64px 0 10px;
    }

    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .access-key-box {
      gap: 200px;
      flex-direction: row-reverse;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        gap: 40px;
      }

      .access-key-img {
        max-width: 592px;
        aspect-ratio: 592/444;

        @media (max-width: $desktop) {
          max-width: 462px;
          aspect-ratio: 462/506;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }

      .access-key-content-wrapper {
        width: 570px;

        @media (max-width: $desktop) {
          width: 100%;
        }

      }
    }
  }

  //data-security-wrapper

  .data-security-wrapper {
    padding: 80px 0;
    background: url("/assets/images/landingPage/footer/footerBeck.png") $backgroundSecondary6 -0.152px 0px / 100.021% 100% no-repeat;

    @media (max-width: $desktop) {
      padding: 64px 0;
    }
    @media (max-width: $tablet) {
      padding: 64px 0 0;
    }

    .data-security-box {
      gap: 64px;

      @media (max-width: $desktop) {
        flex-direction: column-reverse;
        gap: 24px;
      }

      .data-security-img {
        max-width: 572px;
        aspect-ratio: 572/480;


        @media (max-width: $desktop) {
          max-width: 572px;
          aspect-ratio: 572/421;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;

        }
      }

      .data-security-content {
        max-width: 605px;
      }
    }
  }

  //customizable-reports-wrapper
  .customizable-reports-wrapper {
    width: 100%;
    padding: 80px 0;
    background: white;

    @media (max-width: $desktop) {
      padding: 64px 0;
    }

    .customizable-reports-title {
      color: $titleColor;
      font-family: Inter, sans-serif;
      font-size: 34px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: -0.9px;
      margin: 12px 0 16px;

      @media (max-width: $desktop) {
        font-size: 28px;
      }

      @media (max-width: $tablet) {
        font-size: 22px;
        margin: 8px 0 16px;
      }
    }

    .customizable-reports-desc {
      color: $titleColor3;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;

      @media (max-width: $desktop) {
        font-size: 16px;
      }
    }

    .customizable-reports-content {
      display: flex;
      align-items: center;
      margin-top: 16px;

      @media (max-width: $desktop) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
      }

      .customizable-reports-items {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .single-item-wrapper {
          display: flex;
          align-items: start;
          gap: 9px;

          .about-something-svg {
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
          }

          .single-item-texts {
            .single-item-text {
              color: $titleColor3;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }

      .customizable_img {
        max-width: 474px;
        aspect-ratio: 474/316;

        @media (max-width: $desktop) {
          max-width: 688px;
          aspect-ratio: 688/150;
        }

        img {
          width: 100%;
          height: 100%;
        }

      }
    }
  }

  .blue-text {
    color: $themePrimaryBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

//SolutionsCard
.solutionsCard-wrapper {
  position: relative;
  border-radius: 4px;
  background: $backgroundSecondary11;
  padding: 96px 24px;
  margin: 50px 0 0;
  cursor: pointer;

  @media (max-width: $desktop) {
    padding: 24px 64px;
    margin: 0 40px 0 40px;
  }

  @media (max-width: $tablet) {
    padding: 16px;
    margin: 24px 0 0 24px;
  }

  &:hover {
    background: $themePrimaryBold;

    .solutions-card-circle {
      background: $themePrimaryBold;

      .solutions-card-svg {
        path {
          stroke: $backgroundSecondary5;
        }
      }
    }

    .solutions-card-title {
      color: $backgroundSecondary5;
    }

    .solutions-card-description {
      color: $backgroundSecondary5;
    }
  }

  .solutions-card-circle {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    left: calc(50% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 6px solid $backgroundSecondary5;
    background: $backgroundSecondary11;

    @media (max-width: $desktop) {
      width: 80px;
      height: 80px;
      top: calc(50% - 40px);
      left: -40px;
    }

    @media (max-width: $tablet) {
      width: 44px;
      height: 44px;
      top: -24px;
      left: -24px;
      border: 4px solid $backgroundSecondary5;
    }


    .solutions-card-svg {
      width: 35px;
      height: 35px;
      transform: rotate(-45deg);


      @media (max-width: $tablet) {
        width: 20px;
        height: 20px;
      }

      path {
        stroke: black;
      }
    }
  }

  .solutions-card-title {
    color: $titleColor;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;

    @media (max-width: $desktop) {
      font-size: 16px;
    }

  }

  .solutions-card-description {
    color: $titleColor3;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;

    @media (max-width: $desktop) {
      font-size: 18px;
      margin-bottom: 12px;
    }

    @media (max-width: $tablet) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}

//AboutSomething

.about-something-wrapper {
  display: flex;

  .about-something_img {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .about-something-content {
    .about-something_title {
      color: $titleColor;
      font-family: Inter, sans-serif;
      font-size: 34px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.9px;
      margin-top: 12px;

      @media (max-width: $desktop) {
        font-size: 28px;
      }
    }

    .about-something_description {
      color: $titleColor3;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      margin: 16px 0 24px;
    }

    .about-something-content_items-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .single-item-wrapper {
        display: flex;
        align-items: start;
        gap: 9px;

        .about-something-svg {
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
        }

        .single-item-texts {
          .single-item-text {
            color: $titleColor3;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .google-app-buttons {
      display: flex;
      gap: 24px;
      margin-top: 40px;

      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: center;
      }

      .google-app-button {
        @media (max-width: $desktop) {
          width: 100%;
          max-width: 332px;
          gap: 16px;
        }

      }
    }
  }
}

//BillingPostingCard

.billing-posting-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  background: $backgroundSecondary12;
  box-shadow: 0 0 0 1px rgba(29, 29, 32, 0.08);

  .billing-posting-card-img {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .billing-posting-card_content {
    padding: 8px 16px;
    border-radius: 4px;
    background: $textWhite;

    .card_content-title {
      color: $titleColor;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .card_content-desc {
      color: $titleColor3;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
}